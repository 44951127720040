import React, { useState } from "react";

import { Colors } from "../../Helpers";
import { Box, Icon } from "../../Bitter";
import DatePicker from "sassy-datepicker";
import { OuterClick } from "react-outer-click";
import Moment from "moment";
import StringMask from "string-mask";

const Component = ({ value, change, type = "text", placeholder }) => {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(
    Moment(value).isValid() ? Moment(value).format("HH:mm") : "12:00"
  );

  const picker = (date) => {
    let formatted = Moment(date).format("YYYY-MM-DD") + " " + time;
    if (change) {
      change(formatted);
    }
    setOpen(false);
  };

  return (
    <OuterClick onOuterClick={() => setOpen(false)}>
      <Box
        mode="position"
        position="relative"
        overflow="visible"
        style={{ overflow: "visible" }}
      >
        <input
          placeholder={placeholder}
          type={type}
          style={{
            height: 40,
            border: "1.5px solid #F7F7F7",
            borderRadius: 2,
            boxSizing: "border-box",
            width: "100%",
            display: "block",
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 14,
            fontFamily: "Programme Light",
            color: Colors.black,
            outline: "none",
            background: "none",
            backgroundColor: "none",
          }}
          value={value}
          onChange={(event) => {
            setOpen(false);
            let date = StringMask.apply(
              event.target.value.replace(/\D/g, ""),
              "0000-00-00 00:00"
            );
            for (var i = 0; i < 10; i++) {
              setTime(Moment(date).format("HH:mm"));
            }
            if (change) {
              change(date);
            }
          }}
        />
        <Box
          press={() => setOpen(true)}
          position="absolute"
          mode="position"
          top={12}
          right={12}
          left="unset"
          bottom="unset"
        >
          <Icon size={18} opacity={0.5} icon="calendar" />
        </Box>
        {open ? (
          <Box
            mode="position"
            position="absolute"
            left="unset"
            top={0}
            bottom="unset"
            right={0}
            layer={2}
          >
            <DatePicker onChange={picker} />
          </Box>
        ) : undefined}
      </Box>
    </OuterClick>
  );
};

export default Component;
