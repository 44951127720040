import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Mobile,
  Rule,
  Line,
  Button,
  Input,
  Icon,
  Basket,
} from "../Bitter";
import { Colors } from "../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { baskets } = this.props.redux;

    return (
      <>
        <Mobile
          render={(mobile) =>
            baskets.map((basket, s) =>
              s === baskets.length - 1 ? (
                <Box
                  key={basket.uuid}
                  display="block"
                  mode="position"
                  position="fixed"
                  with="100%"
                  height="100%"
                  color="rgba(10,15,13,0.30)"
                  display="flex"
                  align="center"
                  justify="flex-end"
                  direction="column"
                  overflow="auto"
                  style={{ boxSizing: "border-box", padding: 24 }}
                  layer={7}
                >
                  <Basket
                    entities={basket.configuration.entities}
                    label={basket.configuration.label}
                    identifier={basket.configuration.identifier}
                    working={basket.loading}
                    cancel={() => {
                      if (basket.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(baskets);
                      temporary.map((t, tt) => {
                        if (t.uuid === basket.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value: false,
                          };
                        }
                      });
                      this.props.core("baskets", temporary);
                    }}
                    change={(entities) => {
                      if (basket.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(baskets);
                      temporary.map((t, tt) => {
                        if (t.uuid === basket.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value: entities,
                          };
                        }
                      });
                      this.props.core("baskets", temporary);
                    }}
                  />
                </Box>
              ) : undefined
            )
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
