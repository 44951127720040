import React from "react";

import { Colors } from "../../Helpers";
import { Box, Icon } from "../../Bitter";

import HashLoader from "react-spinners/HashLoader";

const Component = ({ value, change, loading = false }) => {
  return (
    <Box display="flex" align="center">
      {loading ? (
        <Box
          right={8}
          opacity={0.5}
          width={16}
          height={16}
          display="inline-flex"
          align="center"
          justify="center"
        >
          <HashLoader color={Colors.black} loading={true} size={16} />
        </Box>
      ) : (
        <Icon icon="search" weight={3} size={16} right={8} opacity={0.5} />
      )}
      <input
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          flex: 1,
          display: "block",
          fontSize: 14,
          fontFamily: "Programme Light",
          color: Colors.black,
          opacity: 0.5,
          outline: "none",
          background: "none",
          backgroundColor: "none",
          border: "none",
          lineHeight: "10px",
          height: 16,
        }}
        placeholder="Search"
        value={value}
        onChange={(event) => (change ? change(event.target.value) : {})}
      />
    </Box>
  );
};

export default Component;
