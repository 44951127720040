import React from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  Input,
  Select,
  D4,
  D3,
  D2,
  D1,
  Entity,
  Entities,
  ConnectedEntities,
  Mobile,
  Attachment,
  Date,
} from "../../Bitter";
import { Colors, Rules, Functions } from "../../Helpers";
import { Pages, API, Debug } from "../../Modules";
import { Empty } from "../../Project";
import { System, Permissions } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Lodash from "lodash";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entity: false,
      loading: true,
      changed: false,
      random: uuidv4(),
      tabs: false,
    };
  }

  async componentDidMount() {
    Debug.set(() => this.setState({ tabs: false }));

    await this.reload(false);

    this.setState({ loading: false });
  }

  trash = async () => {
    const { settings, close } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    let request = Lodash.clone(entity);
    request[settings.api] = request.uuid;

    const trash = await API.request("/@" + settings.api + "/delete", request);
    if (trash.success) {
      if (close) {
        close(true);
      }
    } else {
      this.setState({ loading: false });
    }
  };
  save = async () => {
    const { settings, close, change } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true, changed: false });

    let request = Lodash.clone(entity);
    request[settings.api] = request.uuid;

    if (entity.parameters.password !== undefined) {
      if (entity.parameters.password.length === 0) {
        delete entity.parameters.password;
      }
    }

    const save = await API.request("/@" + settings.api + "/update", request);
    if (save.success) {
      Toast.success(save.message);
      this.setState({ loading: false });
      if (close) {
        change(entity);
      }
    } else {
      this.setState({ loading: false });
    }
  };
  update = async (entity) => {
    this.setState({ entity, changed: true });
    console.log({ entity });
    this.forceUpdate();

    return true;
  };
  reload = async (wait = true) => {
    const { settings } = this.props;
    let request = {};
    request[settings.api] = this.props.entity;
    const entity = await API.request("/@" + settings.api + "/read", request);
    if (entity.success) {
      if (wait) {
        this.setState({ loading: true });
        await Functions.sleep(360);
      }
      this.setState({ entity: entity.data, random: uuidv4(), loading: false });
      this.forceUpdate();
    }
  };

  render() {
    const { settings, close, save, buttons, pending = false } = this.props;
    const { entity, loading, changed, tabs } = this.state;
    const first = Pages.first();

    const { expand } = this.props.redux;

    const UPDATE =
      settings.rules.update && Permissions.permission("update:" + settings.api);

    let WIDTH = null;

    return (
      <Box
        width="100%"
        mode="position"
        height="100%"
        layer={6}
        color="rgba(10,15,13,0.30)"
        position="fixed"
        display="flex"
        align="center"
        justify="center"
        style={{ padding: 24 }}
      >
        <Box
          width="100%"
          height="100%"
          direction="column"
          flex={1}
          display="flex"
          style={{ maxWidth: 720 }}
          color={Colors.white}
          radius={8}
        >
          <Mobile
            render={(mobile) => (
              <Box
                mode="padding"
                all={24}
                direction={mobile ? "column" : "row"}
                align={mobile ? undefined : "center"}
                justify={mobile ? undefined : "space-between"}
                display="flex"
              >
                <Box direction="row" align="center" display="flex">
                  <Icon
                    icon="x"
                    right={12}
                    press={close ? () => close() : undefined}
                  />
                  <Box direction="row" align="flex-end" display="flex">
                    {loading ? undefined : (
                      <Rule rule="Header" right={12}>
                        {settings.label(entity)}
                      </Rule>
                    )}
                    <Rule rule="Body" opacity={0.5}>
                      {System.system().entities[settings.api].label}
                    </Rule>
                  </Box>
                </Box>
                <Box
                  direction="row"
                  align="center"
                  display="flex"
                  left={mobile ? -12 : 0}
                  top={mobile ? 24 : 0}
                >
                  {settings.rules.delete &&
                  Permissions.permission("delete:" + settings.api) ? (
                    <Box left={12}>
                      <Button
                        type="disabled"
                        press={() =>
                          Toast((toast) => (
                            <Box display="flex" align="flex-start">
                              <Box mode="padding" right={12} flex={1}>
                                Silmek istediğinizden emin misiniz? Bu işlem
                                geri alınamaz.
                              </Box>
                              <Button
                                type="dark"
                                press={() => {
                                  Toast.dismiss(toast.id);
                                  this.trash();
                                }}
                              >
                                Yes
                              </Button>
                            </Box>
                          ))
                        }
                      >
                        Delete
                      </Button>
                    </Box>
                  ) : undefined}
                  {settings.rules.update &&
                  Permissions.permission("update:" + settings.api) ? (
                    <Box left={12}>
                      <Button type="dark" press={this.save}>
                        {!changed ? "Saved" : "Save"}
                      </Button>
                    </Box>
                  ) : undefined}
                  {buttons.map((b) =>
                    Permissions.permission("*") ? (
                      <Box left={12}>
                        <Button
                          {...b}
                          press={b.press ? () => b.press(this) : undefined}
                        >
                          {b.label}
                        </Button>
                      </Box>
                    ) : undefined
                  )}
                </Box>
              </Box>
            )}
          />
          <Line />
          <Box
            style={{
              maxWidth: !expand
                ? undefined
                : WIDTH
                ? WIDTH
                : settings.blocks.width
                ? settings.blocks.width
                : 720,
            }}
            width="100%"
            mode="margin"
            left="auto"
            right="auto"
            height="100%"
            overflow="auto"
          >
            {loading || pending ? (
              <Box
                mode="padding"
                top={24}
                bottom={24}
                align="center"
                justify="center"
                display="flex"
              >
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            ) : (
              <Box
                display={loading ? "none" : undefined}
                mode="padding"
                top={24}
                bottom={24}
                right={24}
                left={24}
              >
                {settings.blocks.map((block, b) =>
                  block.type === "custom" &&
                  Rules.get(block.rules(entity, "entity"), "display", true) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {typeof block.jsx === "function"
                        ? block.jsx(entity, this)
                        : block.jsx}
                    </Box>
                  ) : block.type === "string" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Input
                        value={block.value(entity)}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "password" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Input
                        type="password"
                        placeholder="********"
                        value={block.value(entity)}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "number" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Input
                        type="number"
                        value={block.value(entity)}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "select" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Select
                        options={block.options}
                        value={block.value(entity)}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "date" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Date
                        key={b}
                        value={block.value(entity)}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "text" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      {block.label ? (
                        <Rule rule="Body" display="block" bottom={4}>
                          {block.label}
                        </Rule>
                      ) : undefined}
                      <Rule rule="Thin" display="block">
                        {block.value(entity)}
                      </Rule>
                    </Box>
                  ) : block.type === "d4" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {block.rules(entity, "entity") ? (
                          block.rules(entity, "entity").create === true ? (
                            <Icon
                              icon="plus"
                              press={() => {
                                let clone = Lodash.clone(entity);
                                clone.parameters[block.key] =
                                  clone.parameters[block.key] === undefined
                                    ? []
                                    : clone.parameters[block.key];
                                clone.parameters[block.key].push("");
                                this.update(clone);
                              }}
                            />
                          ) : undefined
                        ) : undefined}
                      </Box>
                      <D4
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "d3" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {block.rules(entity, "entity").create ===
                        false ? undefined : (
                          <Icon
                            icon="plus"
                            press={() => {
                              let clone = Lodash.clone(entity);
                              let temporary = {};
                              Lodash.each(
                                block.rules(entity, "entity").labels,
                                (__, ___) => (temporary[___] = "")
                              );
                              clone.parameters[block.key] =
                                clone.parameters[block.key] === undefined
                                  ? []
                                  : clone.parameters[block.key];
                              clone.parameters[block.key].push(temporary);
                              this.update(clone);
                            }}
                          />
                        )}
                      </Box>
                      <D3
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "d2" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {block.rules(entity, "entity").create ===
                        false ? undefined : (
                          <Icon
                            icon="plus"
                            press={() => {
                              let clone = Lodash.clone(entity);
                              clone.parameters[block.key].push("");
                              this.update(clone);
                            }}
                          />
                        )}
                      </Box>
                      <D2
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "d1" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {block.rules(entity, "entity").create ===
                        false ? undefined : (
                          <Icon
                            icon="plus"
                            press={() => {
                              let clone = Lodash.clone(entity);
                              let temporary = {};
                              Lodash.each(
                                block.rules(entity, "entity").labels,
                                (__, ___) => (temporary[___] = "")
                              );
                              clone.parameters[block.key] =
                                clone.parameters[block.key] === undefined
                                  ? []
                                  : clone.parameters[block.key];
                              clone.parameters[block.key].push(temporary);
                              this.update(clone);
                            }}
                          />
                        )}
                      </Box>
                      <D1
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                      />
                    </Box>
                  ) : block.type === "entity" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                      </Box>
                      <Entity
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                        changed={changed}
                      />
                    </Box>
                  ) : block.type === "attachment" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                      </Box>
                      <Attachment
                        value={block.value(entity)}
                        rules={block.rules(entity, "entity")}
                        change={(value) => {
                          if (UPDATE) {
                            this.update(block.change(entity, block.key, value));
                          }
                        }}
                        changed={changed}
                      />
                    </Box>
                  ) : block.type === "entities" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <Box
                        direction="row"
                        display="flex"
                        align="center"
                        justify="space-between"
                        bottom={12}
                      >
                        {block.label ? (
                          <Rule rule="Body" display="block">
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {block.rules(entity, "entity").create ===
                        false ? undefined : (
                          <Icon
                            icon="plus"
                            press={
                              !changed
                                ? () =>
                                    Pages.screen(
                                      "@create/" +
                                        block.rules(entity, "entity").api,
                                      false,
                                      false,
                                      block.rules(entity, "entity").override
                                    )
                                : () =>
                                    Toast((toast) => (
                                      <Box display="flex" align="flex-start">
                                        <Box mode="padding" right={12} flex={1}>
                                          Kaydedilmemiş değişiklikler
                                          kaybolacaktır.
                                        </Box>
                                        <Button
                                          type="dark"
                                          press={() => {
                                            Toast.dismiss(toast.id);
                                            Pages.screen(
                                              "@create/" +
                                                block.rules(entity, "entity")
                                                  .api,
                                              false,
                                              false,
                                              block.rules(entity, "entity")
                                                .override
                                            );
                                          }}
                                        >
                                          Tamam
                                        </Button>
                                      </Box>
                                    ))
                            }
                          />
                        )}
                      </Box>
                      <Entities
                        changed={changed}
                        rules={block.rules(entity, "entity")}
                      />
                    </Box>
                  ) : block.type === "connected" &&
                    Rules.get(
                      block.rules(entity, "entity"),
                      "display",
                      true
                    ) ? (
                    <Box bottom={24}>
                      <ConnectedEntities
                        label={block.label}
                        changed={changed}
                        rules={block.rules(entity, "entity")}
                        reload={this.reload}
                      />
                    </Box>
                  ) : undefined
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
