import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Mobile,
  Rule,
  Line,
  Button,
  Input,
  Icon,
  Choose,
} from "../Bitter";
import { Colors } from "../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { childrens } = this.props.redux;

    return (
      <>
        <Mobile
          render={(mobile) =>
            childrens.map((children, s) =>
              s === childrens.length - 1 ? (
                <Box
                  key={children.uuid}
                  display="block"
                  mode="position"
                  position="fixed"
                  with="100%"
                  height="100%"
                  color="rgba(10,15,13,0.30)"
                  display="flex"
                  align="center"
                  justify="flex-end"
                  direction="column"
                  overflow="auto"
                  style={{ boxSizing: "border-box", padding: 24 }}
                  layer={7}
                >
                  <Choose
                    rules={{
                      api: children.configuration.api,
                      ...children.configuration.rules,
                    }}
                    working={children.loading}
                    filter={children.configuration.filter}
                    cancel={() => {
                      if (children.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(childrens);
                      temporary.map((t, tt) => {
                        if (t.uuid === children.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value: false,
                          };
                        }
                      });
                      this.props.core("childrens", temporary);
                    }}
                    change={(identifiers, entities) => {
                      if (children.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(childrens);
                      temporary.map((t, tt) => {
                        if (t.uuid === children.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value: {
                              identifiers,
                              entities,
                            },
                          };
                        }
                      });
                      this.props.core("childrens", temporary);
                    }}
                  />
                </Box>
              ) : undefined
            )
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
