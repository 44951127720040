import React from "react";

import { Box, Text } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  let display = true;
  if (typeof props.children === "string") {
    if (props.children.length === 0) {
      display = false;
    }
  }

  const { top, right, bottom, left } = props;
  const { color = Colors.white, background = Colors.primary } = props;

  let temporary = {};

  Object.entries(props).map(([key, value]) => {
    if (
      !["top", "left", "top", "bottom", "color", "background"].includes(key)
    ) {
      temporary[key] = value;
    }
  });

  return top || right || bottom || left ? (
    <Box mode="margin" top={top} left={left} right={right} bottom={bottom}>
      <Box
        color={background}
        height={20}
        left={4}
        right={4}
        align="center"
        justify="center"
        display="inline-flex"
        mode="padding"
        radius={2}
        {...temporary}
        style={{ width: display ? undefined : 0, opacity: display ? 1 : 0 }}
      >
        <Text color={color} size={12} line={20}>
          {props.children}
        </Text>
      </Box>
    </Box>
  ) : (
    <Box
      color={background}
      height={20}
      left={4}
      right={4}
      align="center"
      justify="center"
      display="inline-flex"
      mode="padding"
      radius={2}
      {...temporary}
      style={{ width: display ? undefined : 0, opacity: display ? 1 : 0 }}
    >
      <Text color={color} size={12} line={20}>
        {props.children}
      </Text>
    </Box>
  );
};

export default Component;
