import axios from "axios";
import Store from "./Store";
import Toast from "react-hot-toast";
import { Resources } from "../Helpers";

import { ConcurrencyManager } from "axios-concurrency";

let api = axios.create();

const MAX_CONCURRENT_REQUESTS = 20;
ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS);

class API {
  static async request(slug, request = {}) {
    const admin = Store.getState().admin;

    const promise = new Promise((resolve) => {
      api
        .post(Resources.url + slug, request, {
          headers: {
            Authorization: admin,
            Platform: "web",
            Device: "ADMIN",
          },
        })
        .then((res) => {
          const data = res.data;

          if (data.error) {
            Toast.error(data.message);
          }

          console.log({
            url: Resources.url + slug,
            request,
            headers: {
              Authorization: admin,
              Platform: "web",
              Device: "ADMIN",
            },
            response: data.data,
          });

          resolve({
            success: data.success,
            error: data.error,
            message: data.message,
            data: data.data,
          });
        })
        .catch(() => {
          Toast.error("Bir sorun oluştu. Lütfen daha sonra deneyiniz.");

          console.log({
            url: Resources.url + slug,
            request,
            headers: {
              Authorization: admin,
              Platform: "web",
              Device: "ADMIN",
            },
            response: {
              success: false,
              error: true,
              message: "Bir sorun oluştu. Lütfen daha sonra deneyiniz.",
              data: null,
            },
          });

          resolve({
            success: false,
            error: true,
            message: "Bir sorun oluştu. Lütfen daha sonra deneyiniz.",
            data: null,
          });
        });
    });
    const result = await promise;
    return result;
  }

  static source(uuid) {
    return Resources.url + "/picture/" + uuid + ".jpg";
  }
}

export default API;
