import React from "react";
import { connect } from "react-redux";
import { Box, Mobile, Line, Icon, Rule } from "../Bitter";
import {
  SidebarContainer,
  EntitiesContainer,
  EntityContainer,
  CreateContainer,
  AuthContainer,
} from "../Containers";
import { Resources } from "../Helpers";
import { System } from "../System";
import { Pages } from "../Modules";
import Helmet from "react-helmet";
import Drift from "react-driftjs";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  drift = () => {
    window.drifter();
  };

  componentDidMount() {
    window.drifter = () => this.props.core("drift", true);
  }

  render() {
    const {
      screen,
      entity,
      temporary,
      override,
      user,
      sidebar,
      drift,
    } = this.props.redux;

    return user ? (
      <Box width="100%" height="100%" direction="column" display="flex">
        <Helmet>
          <title>
            Welcome Back{" "}
            {user.parameters.name ||
              user.parameters.username ||
              user.parameters.email}
          </title>
        </Helmet>
        <Drift
          appId={Resources.drift}
          eventHandlers={[
            {
              event: "ready",
              function: this.drift,
            },
          ]}
          userId={user.uuid}
          attributes={{
            email: user.parameters.email,
            company: Resources.app,
            name:
              user.parameters.name ||
              user.parameters.username ||
              user.parameters.email,
          }}
        />
        <Box width="100%" height="100%" overflow="hidden" display="flex">
          <SidebarContainer />
          {screen.includes("@entities/") ? (
            <EntitiesContainer
              settings={
                System.system().entities[screen.replace("@entities/", "")]
              }
            />
          ) : screen.includes("@entity/") ? (
            <EntityContainer
              settings={System.system().entity[screen.replace("@entity/", "")]}
              screen={screen}
              entity={entity}
            />
          ) : screen.includes("@create/") ? (
            <CreateContainer
              settings={System.system().entity[screen.replace("@create/", "")]}
              screen={screen}
              entity={entity}
              temporary={temporary}
              override={override}
            />
          ) : undefined}
        </Box>
        {System.system().mobile ? (
          <Mobile
            render={(mobile) =>
              !mobile ? undefined : (
                <>
                  <Line />
                  <Box
                    mode="padding"
                    all={24}
                    direction="row"
                    display="flex"
                    justify="space-between"
                  >
                    {System.system().mobile.map((menu) => (
                      <Box
                        opacity={
                          sidebar
                            ? 0.5
                            : menu.press
                            ? 0.5
                            : menu.custom === true
                            ? screen === menu.value
                              ? 1
                              : 0.5
                            : screen === "@entities/" + menu.value
                            ? 1
                            : 0.5
                        }
                        press={() =>
                          menu.press
                            ? menu.press
                            : menu.custom === true
                            ? Pages.screen(menu.value)
                            : Pages.screen("@entities/" + menu.value)
                        }
                        display="inline-flex"
                        direction="column"
                        align="center"
                      >
                        <Icon icon={menu.icon} />
                        <Rule rule="Small">
                          {menu.short ? menu.short : menu.label}
                        </Rule>
                      </Box>
                    ))}
                    <Box
                      opacity={sidebar ? 1 : 0.5}
                      press={() => this.props.core("sidebar", !sidebar)}
                      display="inline-flex"
                      direction="column"
                      align="center"
                    >
                      <Icon icon="menu" />
                      <Rule rule="Small">Menu</Rule>
                    </Box>
                    {Resources.drift && drift ? <Box width={43} /> : undefined}
                  </Box>
                </>
              )
            }
          />
        ) : undefined}
      </Box>
    ) : (
      <Box width="100%" height="100%" direction="column" display="flex">
        <AuthContainer />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
