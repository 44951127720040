import Toast from "react-hot-toast";
import Store from "./Store";

class Phone {
  static bootstrap() {
    window.addEventListener("message", (message) => this.listen(message.data));
  }

  static async listen(message) {
    if (typeof message === "string") {
      const data = JSON.parse(message);

      return this.answer(data.type, data.data);
    }
  }

  static async call(type, data) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
      return true;
    } catch (Error) {
      console.log(Error);
    }

    return false;
  }

  static async answer(type, data) {
    if (type === "contacts") {
      if (data) {
        Toast.success(data.length + " contacts are fetched.");
      } else {
        Toast.error("Contacts can not be fetched");
      }
    }
    if (type === "web") {
      Toast.success("Message has been recieved from mobile.");
    }
    if (type === "native") {
      Store.dispatch({ type: "CORE", key: "native", value: true });
    }
  }
}

export default Phone;
