import React, { useState, useRef, useEffect } from "react";

import { Colors, Functions } from "../../Helpers";
import { Box, Rule, Icon, Button } from "../../Bitter";
import { Debug } from "../../Modules";

import Lodash from "lodash";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-excel-formula";
import "prismjs/themes/prism.css";

import Excel from "node-xlsx";
import XLSX from "xlsx";
import Moment from "moment";

const Meaning = ({ value }) => {
  const values = value.split(",");
  const data = [];

  let execution = false;

  values.map((v, vv) => {
    if (/\[(.*?)\:*\]/g.test(v)) {
      data.push({
        title: vv,
        type: "any of",
        data: /\[(.*?)\:*\]/g.exec(v)[1].split(":"),
      });
    } else if (/\[(\d*?|\*)\-(\d*?|\*)\]/g.test(v)) {
      execution = /\[(\d*?|\*)\-(\d*?|\*)\]/g.exec(v);
      data.push({
        title: vv,
        type: "between",
        data: [execution[1], execution[2]],
      });
    } else {
      data.push({ title: vv, type: "is", data: [v] });
    }
  });

  return (
    <Box style={{ height: 256, overflowY: "auto" }}>
      {data.map((d) => (
        <Box bottom={24}>
          <Box direction="row" display="flex" align="center" bottom={6}>
            <Rule rule="Body" right={4} size={13}>
              {Functions.ordinal(d.title + 1)}
            </Rule>
            <Rule
              rule="Bold"
              color={
                d.type === "is"
                  ? "#118ab2"
                  : d.type === "any of"
                  ? "#06d6a0"
                  : d.type === "between"
                  ? "#ffd166"
                  : "#073b4c"
              }
              size={13}
            >
              {d.type}
            </Rule>
          </Box>
          <Box direction="row" display="flex" wrap="wrap">
            {d.data.map((dd) => (
              <Box
                display="inline-block"
                mode="padding"
                left={3}
                right={3}
                radius={4}
                border="1.5px solid #DDDDDD"
                style={{ marginRight: 2 }}
              >
                <Rule rule="Thin" size={11}>
                  {dd}
                </Rule>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Component = ({ value, change, rules, label, open, close }) => {
  let data = [];

  if (typeof value === "object") {
    data = value;
  }

  const [datas, set] = useState(data);
  const [expanded, setExpanded] = useState(false);
  const [meaning, setMeaning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [debug, setDebug] = useState(true);

  const {
    labels = {},
    move = true,
    trash = true,
    parameters = {},
    expandable = [],
    duplicate = false,
  } = rules;

  const input = useRef(null);

  const upload = async (file) => {
    const keys = [];
    datas.map((d, dd) => {
      if (dd === 0) {
        Object.entries(d).map(([k, v]) => {
          keys.push(k);
        });
      }
    });

    const base64 = await Functions.read(file);
    const data = XLSX.read(base64, { type: "binary" });

    const bulk = [];

    XLSX.utils
      .sheet_to_json(data.Sheets["Details"], {
        raw: true,
        defval: "",
        header: 1,
        blankrows: false,
      })
      .map((row, line) => {
        let temporary = {};
        row.map((item, i) => {
          if (i < row.length - 1) {
            if (temporary[keys[0]] === undefined) {
              temporary[keys[0]] = "";
            }

            temporary[keys[0]] += item + ",";
          } else {
            /* if (item.startsWith) {
              if (!item.startsWith("=")) {
                item = item.replaceAll(",", ".");
                item = parseFloat(item, 10);
              }
            } */
            temporary[keys[keys.length - 1]] = item;
          }
        });
        temporary[keys[0]] = temporary[keys[0]].slice(0, -1);

        if(debug){
          if (temporary[keys[keys.length - 1]] === undefined) {
            return false;
          }

          if (temporary[keys[keys.length - 1]] === null) {
            return false;
          }

          if (temporary[keys[keys.length - 1]] === "") {
            return false;
          }

          if (typeof temporary[keys[keys.length - 1]] === "string") {
            if (temporary[keys[keys.length - 1]].trim() === "") {
              return false;
            }
          }
        }

        bulk.push(temporary);

        return true;
      });

    set(bulk);

    if (change) {
      change(bulk);
    }
  };

  return !open ? null : (
    <>
      <input
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          var file = event.target.files[0];
          upload(file);
        }}
        type="file"
        id="file"
        ref={input}
        style={{ display: "none" }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Box
        style={{
          display: "flex",
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
          backgroundColor: "rgba(10,15,13,0.10)",
          padding: 24,
          boxSizing: "border-box",
          zIndex: 2,
        }}
      >
        {meaning ? (
          <Box
            mode="padding"
            all={16}
            radius={8}
            shadow="small"
            color={Colors.white}
            width={720}
          >
            <Box
              display="flex"
              align="flex-start"
              justify="space-between"
              bottom={12}
            >
              {labels[expanded.key] ? (
                <Rule rule="Title">{labels[expanded.key]}</Rule>
              ) : undefined}
              <Button type="white" press={() => setMeaning(false)}>
                Tamam
              </Button>
            </Box>
            <Box display="flex" position="relative">
              <Box
                color="#F7F7F7"
                mode="padding"
                all={12}
                radius={8}
                flex={1}
                display="flex"
                direction="column"
                justify="center"
              >
                <Meaning
                  value={
                    datas[expanded.datas][expanded.key]
                      ? datas[expanded.datas][expanded.key].toString()
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : expanded && !meaning ? (
          <Box
            mode="padding"
            all={16}
            radius={8}
            shadow="small"
            color={Colors.white}
            width={720}
          >
            <Box
              display="flex"
              align="flex-start"
              justify="space-between"
              bottom={12}
            >
              {labels[expanded.key] ? (
                <Rule rule="Title">{labels[expanded.key]}</Rule>
              ) : undefined}
              <Box direction="row" display="flex" align="center">
                <Button type="white" press={() => setExpanded(false)}>
                  Tamam
                </Button>
                {(datas[expanded.datas][expanded.key]
                  ? datas[expanded.datas][expanded.key].toString()
                  : ""
                ).length > 0 &&
                !(datas[expanded.datas][expanded.key]
                  ? datas[expanded.datas][expanded.key].toString()
                  : ""
                ).startsWith("=") ? (
                  <Box left={12}>
                    <Button
                      type="disabled"
                      feather={true}
                      icon="info"
                      width={32}
                      press={() => setMeaning(true)}
                    />
                  </Box>
                ) : undefined}
              </Box>
            </Box>
            <Box display="flex" position="relative">
              <Box
                color="#F7F7F7"
                mode="padding"
                all={12}
                radius={8}
                flex={1}
                display="flex"
                direction="column"
                justify="center"
              >
                <Editor
                  style={{
                    border: "1.5px solid #F7F7F7",
                    borderRadius: 2,
                    boxSizing: "border-box",
                    width: "100%",
                    display: "block",
                    fontSize: 13,
                    fontFamily: "Programme Light",
                    color: Colors.black,
                    outline: "none",
                    background: "none",
                    backgroundColor: "none",
                    resize: "none",
                    height: 256,
                  }}
                  highlight={(code) =>
                    highlight(code, languages["excel-formula"])
                  }
                  value={
                    datas[expanded.datas][expanded.key]
                      ? datas[expanded.datas][expanded.key].toString()
                      : ""
                  }
                  onValueChange={(code) => {
                    let clone = Lodash.clone(datas);
                    clone[expanded.datas][expanded.key] = code;
                    set(clone);

                    if (change) {
                      change(clone);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            mode="padding"
            all={16}
            radius={8}
            shadow="small"
            color={Colors.white}
            width={720}
          >
            <Box
              display="flex"
              align="flex-start"
              justify="space-between"
              bottom={12}
            >
              {label ? <Rule rule="Title">{label}</Rule> : undefined}
              <Box direction="row" display="inline-flex">
                <Box left={12}>
                  <Button
                    width={32}
                    icon="download"
                    loading={loading}
                    type="disabled"
                    press={() => {
                      setLoading(true);
                      let file = "";
                      const page = {
                        name: "Details",
                        data: [],
                      };

                      let max = 0;

                      datas.map((d, dd) => {
                        Object.entries(d).map(([k, v], kk) => {
                          if (kk === 0 && v.split) {
                            if (v.split(",").length > max) {
                              max = v.split(",").length;
                            }
                          }
                        });
                      });

                      datas.map((d, dd) => {
                        let r = [];
                        Object.entries(d).map(([k, v], kk) => {
                          if (kk === 0 && v.split) {
                            let splitted = v.split(",");
                            for (var i = 0; i < max; i++) {
                              if (splitted[i]) {
                                r.push(splitted[i]);
                              } else {
                                r.push("");
                              }
                            }
                          } else {
                            r.push(v);
                          }
                        });

                        page.data.push(r);
                      });

                      file = Moment().format("YYYY-MM-DD-HH-mm-ss-") + "d5";

                      var buffer = Excel.build([page], {
                        "!cols": [{ wch: 20 }, { wch: 50 }, { wch: 50 }],
                      });

                      Functions.download(
                        buffer,
                        file,
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      );

                      setLoading(false);
                    }}
                  />
                </Box>
                <Box left={12}>
                  <Button
                    width={32}
                    icon="upload"
                    loading={loading}
                    type={debug ? "dark" : "disabled"}
                    press={() => {
                      if (input.current) {
                        input.current.click();
                      }
                    }}
                  />
                </Box>
                <Box left={12}>
                  <Button
                    type="dark"
                    press={() => {
                      let temporary = Lodash.clone(datas);
                      temporary.push(parameters);
                      set(temporary);

                      if (change) {
                        change(temporary);
                      }
                    }}
                  >
                    Create
                  </Button>
                </Box>
                <Box left={12}>
                  <Button type="white" press={close}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              style={{ maxHeight: "50vh" }}
              overflow="auto"
              right={20}
              mode="padding"
            >
              {datas.map((_, __) => (
                <Box
                  display="flex"
                  mode="margin"
                  bottom={__ + 1 < datas.length ? 8 : 0}
                >
                  <Box
                    mode="margin"
                    left={-4}
                    right={4}
                    display="flex"
                    flex={1}
                    direction="row"
                  >
                    {typeof _ === "string" ? (
                      <Box left={4} right={4} mode="margin" flex={1}>
                        <Box display="flex">
                          <Box
                            color="#F7F7F7"
                            mode="padding"
                            all={12}
                            radius={8}
                            flex={1}
                            height={64}
                            display="flex"
                            direction="column"
                            justify="center"
                          >
                            <input
                              style={{
                                border: "1.5px solid #F7F7F7",
                                borderRadius: 2,
                                boxSizing: "border-box",
                                width: "100%",
                                display: "block",
                                fontSize: 16,
                                fontFamily: "Programme Regular",
                                color: Colors.black,
                                outline: "none",
                                background: "none",
                                backgroundColor: "none",
                              }}
                              value={_}
                              onChange={(event) => {
                                let clone = Lodash.clone(datas);
                                clone[__] = event.target.value;
                                set(clone);

                                if (change) {
                                  change(clone);
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      Object.entries(_).map(([___, ____]) => {
                        return (
                          <Box
                            left={4}
                            right={4}
                            mode="margin"
                            bottom={12}
                            flex={1}
                          >
                            <Box display="flex">
                              <Box
                                color="#F7F7F7"
                                mode="padding"
                                all={12}
                                radius={8}
                                flex={1}
                                height={64}
                                display="flex"
                                direction="column"
                                justify="center"
                                position="relative"
                              >
                                {expandable.includes(___) ? (
                                  <Box
                                    position="absolute"
                                    mode="position"
                                    top={4}
                                    right={4}
                                    bottom="unset"
                                    left="unset"
                                    press={() =>
                                      setExpanded({
                                        datas: __,
                                        key: ___,
                                      })
                                    }
                                  >
                                    <Icon
                                      feather={true}
                                      icon="maximize"
                                      size={16}
                                    />
                                  </Box>
                                ) : undefined}
                                {labels[___] ? (
                                  <Rule
                                    bottom={4}
                                    display="block"
                                    rule="Small"
                                    opacity={0.5}
                                  >
                                    {labels[___]}
                                  </Rule>
                                ) : (
                                  ___
                                )}
                                <input
                                  style={{
                                    border: "1.5px solid #F7F7F7",
                                    borderRadius: 2,
                                    boxSizing: "border-box",
                                    width: "100%",
                                    display: "block",
                                    fontSize: 16,
                                    fontFamily: "Programme Regular",
                                    color: Colors.black,
                                    outline: "none",
                                    background: "none",
                                    backgroundColor: "none",
                                  }}
                                  value={____}
                                  onChange={(event) => {
                                    let clone = Lodash.clone(datas);
                                    clone[__][___] = event.target.value;
                                    set(clone);

                                    if (change) {
                                      change(clone);
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    )}
                  </Box>
                  <Box
                    display="flex"
                    align="center"
                    direction="column"
                    left={12}
                  >
                    {trash ? (
                      <Icon
                        size={16}
                        opacity={0.5}
                        icon="minus"
                        press={() => {
                          let clone = Lodash.clone(datas);
                          clone.splice(__, 1);
                          set(clone);

                          if (change) {
                            change(clone);
                          }
                        }}
                      />
                    ) : undefined}
                    {move ? (
                      <>
                        <Icon
                          size={16}
                          opacity={0.5}
                          icon="chevron-up"
                          press={() => {
                            let clone = Lodash.clone(datas);

                            if (__ > 0) {
                              let temporary = Lodash.clone(clone[__ - 1]);
                              clone[__ - 1] = clone[__];
                              clone[__] = temporary;
                              set(clone);
                              if (change) {
                                change(clone);
                              }
                            }
                          }}
                        />
                        <Icon
                          size={16}
                          opacity={0.5}
                          icon="chevron-down"
                          press={() => {
                            let clone = Lodash.clone(datas);

                            if (__ < clone.length - 1) {
                              let temporary = Lodash.clone(clone[__ + 1]);
                              clone[__ + 1] = clone[__];
                              clone[__] = temporary;
                              set(clone);
                              if (change) {
                                change(clone);
                              }
                            }
                          }}
                        />
                      </>
                    ) : undefined}
                    {duplicate ? (
                      <Icon
                        size={16}
                        opacity={0.5}
                        icon="copy"
                        press={() => {
                          let clone = Lodash.clone(datas);
                          clone.push(Lodash.clone(_));
                          set(clone);

                          if (change) {
                            change(clone);
                          }
                        }}
                      />
                    ) : undefined}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Component;
