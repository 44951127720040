class Functions {
  static ordinal(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  static letter(number) {
    var s = "",
      t;

    while (number > 0) {
      t = (number - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      number = ((number - t) / 26) | 0;
    }
    return s || undefined;
  }

  static url(string) {
    let url;

    try {
      url = new URL(string);
    } catch (Error) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static install(data, name, type = "text/plain") {
    const a = document.createElement("a");
    a.style.display = "none";
    a.setAttribute("href", data);
    a.setAttribute("download", name);
    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static bytes(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  static identifier(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let random = this.random(1, 10);

    for (var i = 0; i < random; i++) {
      characters = characters
        .split("")
        .sort(function () {
          return 0.5 - Math.random();
        })
        .join("");
    }

    var chars = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * chars));
    }
    return result;
  }

  static random(min = 0, max = 1) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static slugify(text, lower = true) {
    text = text.replace(/^\s+|\s+$/g, "");
    if (lower) {
      text = text.toLowerCase();
    }
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      text = text.replace(
        new RegExp(from.charAt(i).toUpperCase(), "g"),
        to.charAt(i).toUpperCase()
      );
    }

    text = text
      .replace(/[^A-Za-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    return text;
  }

  static download(data, name, type = "text/plain") {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob([data], { type }));
    a.target = "_blank";
    a.setAttribute("download", name);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  static async read(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsBinaryString(file);
    });
  }

  static async buffer(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }
}

export default Functions;
