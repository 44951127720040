import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entities(
      "attachments",
      "Attachments",
      [],
      [
        M.c.i("Name", ({ name }) => name),
        M.c.s("Extension", ({ extension }) => extension.toUpperCase()),
        M.c.s("Size", ({ size }) => Functions.bytes(size)),
        M.c.s("Date", ({ date }) =>
          Moment(date).format("D MMMM YYYY HH:mm")
        ),
      ],
      false,
      { date: Moment().format("YYYY-MM-DD") }
    );
  },
};

export default Bite;
