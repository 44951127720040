import { Box, Rule } from "../Bitter";
import { Colors, Images } from "../Helpers";

const Component = ({ title, description, color = Colors.black }) => (
  <Box
    style={{
      width: "100%",
      maxWidth: 560,
      marginLeft: "auto",
      marginRight: "auto",
      padding: 24,
    }}
  >
    <img
      src={Images.src("assets/i/cactus.svg")}
      style={{
        width: "100%",
        display: "block",
        height: "auto",
        marginBottom: 24,
      }}
    />
    {title ? (
      <Rule
        color={color}
        rule="Title"
        bottom={24}
        display="block"
        align="center"
      >
        {title}
      </Rule>
    ) : undefined}
    {description ? (
      <Rule
        color={color}
        rule="Body"
        bottom={24}
        display="block"
        align="center"
      >
        {description}
      </Rule>
    ) : undefined}
  </Box>
);

export default Component;
