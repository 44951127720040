import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  Input,
  Select,
  D4,
  D3,
  D2,
  D1,
  Entity,
  Mobile,
  Attachment,
  Date
} from "../Bitter";
import { Colors, Rules } from "../Helpers";
import { Empty } from "../Project";
import { Pages, API, Remember, Debug } from "../Modules";
import { System, Permissions } from "../System";
import PuffLoader from "react-spinners/PuffLoader";
import Lodash from "lodash";
import Toast from "react-hot-toast";

const OBJECT = (data) => {
  if (typeof data === "object" && !Array.isArray(data) && data !== null) {
    return true;
  }
  return false;
};

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entity: false,
      loading: true,
      changed: true,
      tabs: false,
    };
  }

  async componentDidMount() {
    Debug.set(() => this.setState({ tabs: false }));

    const { settings, temporary, override } = this.props;
    let tabs = false;
    if (OBJECT(settings.blocks)) {
      settings.blocks.tabs.map((tab) => {
        if (tab.display !== false && !tabs) {
          this.setState({ tabs: tab.label });
          tabs = tab.label;
        }
      });
    }

    if (temporary) {
      if (override) {
        let overriden = Lodash.clone(temporary);
        overriden.parameters = { ...overriden.parameters, ...override };
        this.setState({
          entity: overriden,
          loading: false,
        });
      } else {
        this.setState({ entity: temporary, loading: false });
      }
    } else {
      const entity = await API.request("/@" + settings.api + "/temporary");
      if (entity.success) {
        if (override) {
          let overriden = Lodash.clone(entity.data);
          overriden.parameters = { ...overriden.parameters, ...override };
          this.setState({ entity: overriden, loading: false });
        } else {
          this.setState({ entity: entity.data, loading: false });
        }
      }
    }
  }

  create = async () => {
    const { settings } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    const create = await API.request("/@" + settings.api + "/create", entity);
    if (create.success) {
      const remember = Remember.think();
      Remember.forget();

      if (remember) {
        if (remember.action) {
          if (remember.action.create) {
            await remember.action.create(create);
          }
        }
      }
      Toast.success(create.message);
      await Pages.previous();
      await Pages.screen("@entity/" + settings.api, create.data.uuid);
    } else {
      this.setState({ loading: false });
    }
  };

  update = async (entity) => {
    this.setState({ entity });
    this.forceUpdate();

    console.log(entity.parameters.specifications);

    return true;
  };

  render() {
    const { settings } = this.props;

    const { entity, loading, changed, tabs } = this.state;
    const first = Pages.first();

    const { expand } = this.props.redux;

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <Mobile
          render={(mobile) => (
            <Box
              mode="padding"
              all={24}
              direction="row"
              align="center"
              justify="space-between"
              display="flex"
              direction={mobile ? "column" : "row"}
              align={mobile ? undefined : "center"}
              justify={mobile ? undefined : "space-between"}
            >
              <Box direction="row" align="center" display="flex">
                {first ? undefined : (
                  <Icon
                    icon="chevron-left"
                    right={12}
                    press={() => Pages.previous()}
                  />
                )}
                <Box direction="row" align="flex-end" display="flex">
                  {loading ? undefined : (
                    <Rule rule="Header" right={12}>
                      {settings.label(entity)}
                    </Rule>
                  )}
                  <Rule rule="Body" opacity={0.5}>
                    {System.system().entities[settings.api].label}
                  </Rule>
                </Box>
              </Box>
              {Permissions.permission("create:" + settings.api) ? (
                <Box
                  direction="row"
                  align="center"
                  display="flex"
                  left={mobile ? -12 : 0}
                  top={mobile ? 24 : 0}
                >
                  <Box left={12}>
                    <Button type="dark" press={this.create}>
                      Create
                    </Button>
                  </Box>
                </Box>
              ) : undefined}
            </Box>
          )}
        />
        <Line />
        <Box
          style={{ maxWidth: !expand ? undefined : 560 }}
          width="100%"
          mode="margin"
          left="auto"
          right="auto"
          height="100%"
          overflow="auto"
        >
          {OBJECT(settings.blocks) && tabs === false ? (
            <Empty
              title="Oops"
              description="This place looks empty. If you think this is an error, please get in touch with support."
            />
          ) : undefined}
          {loading ? (
            <Box
              mode="padding"
              top={24}
              bottom={24}
              align="center"
              justify="center"
              display="flex"
            >
              <PuffLoader color={Colors.primary} loading={true} size={32} />
            </Box>
          ) : (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              {OBJECT(settings.blocks) ? (
                <Box
                  mode="padding"
                  all={24}
                  display="flex"
                  align="center"
                  justify="center"
                >
                  <Box
                    display="inline-flex"
                    align="center"
                    justify="center"
                    height={40}
                    radius={8}
                    color="#F7F7F7"
                    mode="padding"
                    all={4}
                    overflow="hidden"
                  >
                    {settings.blocks.tabs.map((tab) => {
                      if (tab.display === false) {
                        return null;
                      }

                      return (
                        <Box
                          mode="padding"
                          left={12}
                          right={12}
                          height={32}
                          radius={4}
                          color={tabs === tab.label ? "white" : "transparent"}
                          shadow={tabs === tab.label ? "large" : undefined}
                          display="inline-flex"
                          align="center"
                          press={() => this.setState({ tabs: tab.label })}
                        >
                          {tab.label}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : undefined}
              {OBJECT(settings.blocks)
                ? settings.blocks.tabs.map((tab) => {
                    if (tab.display === false || tab.label !== tabs) {
                      return null;
                    }
                    return tab.blocks.map((block, b) =>
                      block.type === "attachment" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Attachment
                            value={block.value(entity)}
                            rules={block.rules(entity, "entity")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                            changed={changed}
                          />
                        </Box>
                      ) : block.type === "string" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Input
                            rules={block.rules(entity, "entity")}
                            value={block.value(entity)}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "number" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Input
                            type="number"
                            value={block.value(entity)}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "select" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Select
                            label={block.label}
                            options={block.options}
                            value={block.value(entity)}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "date" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Date
                            key={b}
                            value={block.value(entity)}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "text" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          <Rule rule="Thin" display="block">
                            {block.value(entity)}
                          </Rule>
                        </Box>
                      ) : block.type === "d4" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          <Box
                            direction="row"
                            display="flex"
                            align="center"
                            justify="space-between"
                            bottom={12}
                          >
                            {block.label ? (
                              <Rule rule="Body" display="block">
                                {block.label}
                              </Rule>
                            ) : undefined}
                            {block.rules(entity, "create") ? (
                              block.rules(entity, "create").create === true ? (
                                <Icon
                                  icon="plus"
                                  press={() => {
                                    let clone = Lodash.clone(entity);
                                    clone.parameters[block.key] =
                                      clone.parameters[block.key] === undefined
                                        ? []
                                        : clone.parameters[block.key];
                                    clone.parameters[block.key].push("");
                                    this.update(clone);
                                  }}
                                />
                              ) : undefined
                            ) : undefined}
                          </Box>
                          <D4
                            value={block.value(entity)}
                            rules={block.rules(entity, "create")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "d3" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          <Box
                            direction="row"
                            display="flex"
                            align="center"
                            justify="space-between"
                            bottom={12}
                          >
                            {block.label ? (
                              <Rule rule="Body" display="block">
                                {block.label}
                              </Rule>
                            ) : undefined}
                            {block.rules(entity, "create").create ===
                            false ? undefined : (
                              <Icon
                                icon="plus"
                                press={() => {
                                  let clone = Lodash.clone(entity);
                                  let temporary = {};
                                  Lodash.each(
                                    block.rules(entity, "create").labels,
                                    (__, ___) => (temporary[___] = "")
                                  );
                                  clone.parameters[block.key] =
                                    clone.parameters[block.key] === undefined
                                      ? []
                                      : clone.parameters[block.key];
                                  clone.parameters[block.key].push(temporary);
                                  this.update(clone);
                                }}
                              />
                            )}
                          </Box>
                          <D3
                            value={block.value(entity)}
                            rules={block.rules(entity, "create")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "d2" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          <Box
                            direction="row"
                            display="flex"
                            align="center"
                            justify="space-between"
                            bottom={12}
                          >
                            {block.label ? (
                              <Rule rule="Body" display="block">
                                {block.label}
                              </Rule>
                            ) : undefined}
                            {block.rules(entity, "create").create ===
                            false ? undefined : (
                              <Icon
                                icon="plus"
                                press={() => {
                                  let clone = Lodash.clone(entity);
                                  clone.parameters[block.key].push("");
                                  this.update(clone);
                                }}
                              />
                            )}
                          </Box>
                          <D2
                            value={block.value(entity)}
                            rules={block.rules(entity, "create")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "d1" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.rules(entity, "create").header ? undefined : (
                            <Box
                              direction="row"
                              display="flex"
                              align="center"
                              justify="space-between"
                              bottom={12}
                            >
                              {block.label ? (
                                <Rule rule="Body" display="block">
                                  {block.label}
                                </Rule>
                              ) : undefined}
                              {block.rules(entity, "create").create ===
                              false ? undefined : (
                                <Icon
                                  icon="plus"
                                  press={() => {
                                    let clone = Lodash.clone(entity);
                                    let temporary = {};
                                    Lodash.each(
                                      block.rules(entity, "create").labels,
                                      (__, ___) => (temporary[___] = "")
                                    );
                                    clone.parameters[block.key] =
                                      clone.parameters[block.key] === undefined
                                        ? []
                                        : clone.parameters[block.key];
                                    clone.parameters[block.key].push(temporary);
                                    this.update(clone);
                                  }}
                                />
                              )}
                            </Box>
                          )}
                          <D1
                            value={block.value(entity)}
                            rules={block.rules(entity, "create")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                          />
                        </Box>
                      ) : block.type === "entity" &&
                        Rules.get(
                          block.rules(entity, "create"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          <Box
                            direction="row"
                            display="flex"
                            align="center"
                            justify="space-between"
                            bottom={12}
                          >
                            {block.label ? (
                              <Rule rule="Body" display="block">
                                {block.label}
                              </Rule>
                            ) : undefined}
                          </Box>
                          <Entity
                            value={block.value(entity)}
                            rules={block.rules(entity, "create")}
                            change={(value) =>
                              this.update(
                                block.change(entity, block.key, value)
                              )
                            }
                            changed={changed}
                          />
                        </Box>
                      ) : block.type === "custom" &&
                        Rules.get(
                          block.rules(entity, "entity"),
                          "display",
                          true
                        ) ? (
                        <Box bottom={24}>
                          {block.label ? (
                            <Rule rule="Body" display="block" bottom={4}>
                              {block.label}
                            </Rule>
                          ) : undefined}
                          {typeof block.jsx === "function"
                            ? block.jsx(entity, this)
                            : block.jsx}
                        </Box>
                      ) : undefined
                    );
                  })
                : settings.blocks.map((block, b) =>
                    block.type === "attachment" &&
                    Rules.get(
                      block.rules(entity, "create"),
                      "display",
                      true
                    ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Attachment
                          value={block.value(entity)}
                          rules={block.rules(entity, "entity")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                          changed={changed}
                        />
                      </Box>
                    ) : block.type === "custom" &&
                      Rules.get(
                        block.rules(entity, "entity"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        {typeof block.jsx === "function"
                          ? block.jsx(entity, this)
                          : block.jsx}
                      </Box>
                    ) : block.type === "string" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Input
                          rules={block.rules(entity, "entity")}
                          value={block.value(entity)}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "number" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Input
                          type="number"
                          value={block.value(entity)}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "select" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Select
                          options={block.options}
                          value={block.value(entity)}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "date" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Date
                          key={b}
                          value={block.value(entity)}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "text" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.label ? (
                          <Rule rule="Body" display="block" bottom={4}>
                            {block.label}
                          </Rule>
                        ) : undefined}
                        <Rule rule="Thin" display="block">
                          {block.value(entity)}
                        </Rule>
                      </Box>
                    ) : block.type === "d4" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        <Box
                          direction="row"
                          display="flex"
                          align="center"
                          justify="space-between"
                          bottom={12}
                        >
                          {block.label ? (
                            <Rule rule="Body" display="block">
                              {block.label}
                            </Rule>
                          ) : undefined}
                          {block.rules(entity, "create") ? (
                            block.rules(entity, "create").create === true ? (
                              <Icon
                                icon="plus"
                                press={() => {
                                  let clone = Lodash.clone(entity);
                                  clone.parameters[block.key] =
                                    clone.parameters[block.key] === undefined
                                      ? []
                                      : clone.parameters[block.key];
                                  clone.parameters[block.key].push("");
                                  this.update(clone);
                                }}
                              />
                            ) : undefined
                          ) : undefined}
                        </Box>
                        <D4
                          value={block.value(entity)}
                          rules={block.rules(entity, "create")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "d3" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        <Box
                          direction="row"
                          display="flex"
                          align="center"
                          justify="space-between"
                          bottom={12}
                        >
                          {block.label ? (
                            <Rule rule="Body" display="block">
                              {block.label}
                            </Rule>
                          ) : undefined}
                          {block.rules(entity, "create").create ===
                          false ? undefined : (
                            <Icon
                              icon="plus"
                              press={() => {
                                let clone = Lodash.clone(entity);
                                let temporary = {};
                                Lodash.each(
                                  block.rules(entity, "create").labels,
                                  (__, ___) => (temporary[___] = "")
                                );
                                clone.parameters[block.key] =
                                  clone.parameters[block.key] === undefined
                                    ? []
                                    : clone.parameters[block.key];
                                clone.parameters[block.key].push(temporary);
                                this.update(clone);
                              }}
                            />
                          )}
                        </Box>
                        <D3
                          value={block.value(entity)}
                          rules={block.rules(entity, "create")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "d2" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        <Box
                          direction="row"
                          display="flex"
                          align="center"
                          justify="space-between"
                          bottom={12}
                        >
                          {block.label ? (
                            <Rule rule="Body" display="block">
                              {block.label}
                            </Rule>
                          ) : undefined}
                          {block.rules(entity, "create").create ===
                          false ? undefined : (
                            <Icon
                              icon="plus"
                              press={() => {
                                let clone = Lodash.clone(entity);
                                clone.parameters[block.key].push("");
                                this.update(clone);
                              }}
                            />
                          )}
                        </Box>
                        <D2
                          value={block.value(entity)}
                          rules={block.rules(entity, "create")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "d1" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        {block.rules(entity, "create").header ? undefined : (
                          <Box
                            direction="row"
                            display="flex"
                            align="center"
                            justify="space-between"
                            bottom={12}
                          >
                            {block.label ? (
                              <Rule rule="Body" display="block">
                                {block.label}
                              </Rule>
                            ) : undefined}
                            {block.rules(entity, "create").create ===
                            false ? undefined : (
                              <Icon
                                icon="plus"
                                press={() => {
                                  let clone = Lodash.clone(entity);
                                  let temporary = {};
                                  Lodash.each(
                                    block.rules(entity, "create").labels,
                                    (__, ___) => (temporary[___] = "")
                                  );
                                  clone.parameters[block.key] =
                                    clone.parameters[block.key] === undefined
                                      ? []
                                      : clone.parameters[block.key];
                                  clone.parameters[block.key].push(temporary);
                                  this.update(clone);
                                }}
                              />
                            )}
                          </Box>
                        )}
                        <D1
                          value={block.value(entity)}
                          rules={block.rules(entity, "create")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                        />
                      </Box>
                    ) : block.type === "entity" &&
                      Rules.get(
                        block.rules(entity, "create"),
                        "display",
                        true
                      ) ? (
                      <Box bottom={24}>
                        <Box
                          direction="row"
                          display="flex"
                          align="center"
                          justify="space-between"
                          bottom={12}
                        >
                          {block.label ? (
                            <Rule rule="Body" display="block">
                              {block.label}
                            </Rule>
                          ) : undefined}
                        </Box>
                        <Entity
                          value={block.value(entity)}
                          rules={block.rules(entity, "create")}
                          change={(value) =>
                            this.update(block.change(entity, block.key, value))
                          }
                          changed={changed}
                        />
                      </Box>
                    ) : undefined
                  )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
