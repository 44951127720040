import React from "react";
import { connect } from "react-redux";
import { Box, Mobile, Rule, Line, Button, Input, Icon } from "../../Bitter";
import { Colors } from "../../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { actions, visible, press } = this.props;

    return !visible ? null : (
      <>
        <Mobile
          render={(mobile) => (
            <Box
              display="block"
              mode="position"
              position="fixed"
              with="100%"
              height="100%"
              color="rgba(10,15,13,0.30)"
              display="flex"
              align="center"
              justify="flex-end"
              direction="column"
              overflow="auto"
              style={{ boxSizing: "border-box", padding: 24 }}
              layer={999}
            >
              {actions.map((action) => (
                <Box
                  all={12}
                  mode="padding"
                  display="flex"
                  direction="row"
                  align="center"
                  style={{ marginTop: 12, maxWidth: 256 }}
                  color="white"
                  radius={8}
                  width="100%"
                  press={() => {
                    if (press) {
                      press(action.value);
                    }
                  }}
                >
                  <Icon icon={action.icon} size={16} right={12} opacity={0.5} />
                  {action.text}
                </Box>
              ))}
              <Box
                all={12}
                mode="padding"
                display="flex"
                direction="row"
                align="center"
                style={{ marginTop: 12, maxWidth: 256, color: Colors.white }}
                color="#e76f51"
                radius={8}
                width="100%"
                press={() => {
                  if (press) {
                    press(false);
                  }
                }}
              >
                <Icon
                  color={Colors.white}
                  icon="x"
                  size={16}
                  right={12}
                  opacity={0.5}
                />
                Cancel
              </Box>
            </Box>
          )}
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
