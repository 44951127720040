import Store from "./Store";
import { Functions } from "../Helpers";
import Lodash from "lodash";

class Children {
  static childrens() {
    return Store.getState().childrens;
  }

  static children() {}

  static async quick(api, filter = {}, rules = {}) {
    let uuid = Functions.identifier(64);
    let children = {
      uuid: uuid,
      configuration: {
        cancel: true,
        api,
        filter,
        rules,
      },
      result: false,
      store: false,
      loading: false,
    };

    let childrens = Lodash.clone(this.childrens());
    childrens.push(children);
    Store.dispatch({ type: "CORE", key: "childrens", value: childrens });

    const check = await this.check(uuid);
    return check;
  }

  static async check(uuid) {
    const promise = new Promise((resolve) => {
      const interval = setInterval(() => {
        let childrens = this.childrens();
        childrens.map((children) => {
          if (children.uuid === uuid) {
            if (children.result) {
              resolve(children.result.value);
              this.clean(uuid);
            }
          }
        });
      }, 1000);
    });
    const check = await promise;
    return check;
  }

  static clean(uuid) {
    let childrens = this.childrens();
    Lodash.remove(childrens, {
      uuid,
    });
    Store.dispatch({ type: "CORE", key: "childrens", value: childrens });
  }
}

export default Children;
