import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search, Icon } from "../../Bitter";
import { API, Pages, Remember } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entities: [],
      choose: false,
      connect: [],
      search: "",
    };
  }

  async componentDidMount() {
    const { rules } = this.props;
    const connector = await API.request("/@" + rules.api + "/read");
    const connect = await API.request("/@" + rules.connect + "/read");

    let entities = connector.data.filter(rules.filter);
    const data = [];

    entities.map((entity) => {
      connect.data.map((connected) => {
        if (connected.uuid === entity.parameters[rules.connector]) {
          data.push({ ...connected, connector: entity.uuid });
        }
        return true;
      });
      return true;
    });

    this.setState({ entities: data, connect: connect.data, loading: false });
  }

  render() {
    const { loading, entities, choose, connect, search } = this.state;
    const { rules, changed, reload, label } = this.props;
    const settings = System.entities[rules.connect];

    return (
      <>
        <Box
          direction="row"
          display="flex"
          align="center"
          justify="space-between"
          bottom={12}
        >
          {label ? (
            <Rule rule="Body" display="block">
              {label}
            </Rule>
          ) : undefined}
          {rules.create === false ? undefined : (
            <Icon icon="plus" press={() => this.setState({ choose: true })} />
          )}
        </Box>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.10)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{settings.label}</Rule>
                <Box display="inline-flex" align="center" direction="row">
                  {settings.rules.create ? (
                    <Box left={12}>
                      <Button
                        type="dark"
                        press={
                          !changed
                            ? () => {
                                Remember.remember({
                                  action: {
                                    create: async (entity) => {
                                      if (entity.success) {
                                        let parameters = {};

                                        if (rules.override) {
                                          parameters = {
                                            ...parameters,
                                            ...rules.override,
                                          };
                                        }

                                        parameters[rules.connector] =
                                          entity.data.uuid;

                                        await API.request(
                                          "/@" + rules.api + "/create",
                                          { parameters }
                                        );

                                        Pages.previous();
                                        Pages.previous();
                                      }
                                    },
                                  },
                                });
                                Pages.screen(
                                  "@create/" + rules.connect,
                                  false,
                                  false
                                );
                              }
                            : () =>
                                Toast((toast) => (
                                  <Box display="flex" align="flex-start">
                                    <Box mode="padding" right={12} flex={1}>
                                      Kaydedilmemiş değişiklikler kaybolacaktır.
                                    </Box>
                                    <Button
                                      type="dark"
                                      press={() => {
                                        Remember.remember({
                                          action: {
                                            create: async (entity) => {
                                              if (entity.success) {
                                                let parameters = {};

                                                if (rules.override) {
                                                  parameters = {
                                                    ...parameters,
                                                    ...rules.override,
                                                  };
                                                }

                                                parameters[rules.connector] =
                                                  entity.data.uuid;

                                                await API.request(
                                                  "/@" + rules.api + "/create",
                                                  { parameters }
                                                );

                                                Pages.previous();
                                                Pages.previous();
                                              }
                                            },
                                          },
                                        });
                                        Pages.screen(
                                          "@create/" + rules.connect,
                                          false,
                                          false
                                        );
                                      }}
                                    >
                                      Tamam
                                    </Button>
                                  </Box>
                                ))
                        }
                      >
                        Create
                      </Button>
                    </Box>
                  ) : undefined}
                  <Box left={12}>
                    <Button
                      type="white"
                      press={() => this.setState({ choose: false })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
              {connect.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {connect.map((entity) =>
                    search.length > 0 ? (
                      JSON.stringify(entity).includes(search)
                    ) : true ? (
                      <Box
                        key={entity.uuid}
                        mode="padding"
                        top={8}
                        bottom={8}
                        display="flex"
                        align="center"
                        direction="row"
                      >
                        {settings.columns.map((column) =>
                          column.type === "identifier" ? (
                            <Box
                              flex={1}
                              display="flex"
                              direction="column"
                              mode="padding"
                              right={16}
                            >
                              <Rule rule="Body" bottom={4}>
                                {column.value(entity)}
                              </Rule>
                              <Rule rule="Small" opacity={0.5}>
                                {entity.uuid}
                              </Rule>
                            </Box>
                          ) : undefined
                        )}
                        <Button
                          type="disabled"
                          size="small"
                          press={
                            !changed
                              ? async () => {
                                  let parameters = {};
                                  parameters[rules.connector] = entity.uuid;

                                  if (rules.override) {
                                    parameters = {
                                      ...parameters,
                                      ...rules.override,
                                    };
                                  }

                                  parameters[rules.connector] = entity.uuid;

                                  await API.request(
                                    "/@" + rules.api + "/create",
                                    { parameters }
                                  );

                                  if (reload) {
                                    await reload();
                                  }
                                }
                              : () =>
                                  Toast((toast) => (
                                    <Box display="flex" align="flex-start">
                                      <Box mode="padding" right={12} flex={1}>
                                        Kaydedilmemiş değişiklikler
                                        kaybolacaktır.
                                      </Box>
                                      <Button
                                        type="dark"
                                        press={async () => {
                                          let parameters = {};
                                          parameters[rules.connector] =
                                            entity.uuid;

                                          if (rules.override) {
                                            parameters = {
                                              ...parameters,
                                              ...rules.override,
                                            };
                                          }

                                          parameters[rules.connector] =
                                            entity.uuid;

                                          await API.request(
                                            "/@" + rules.api + "/create",
                                            { parameters }
                                          );

                                          if (reload) {
                                            await reload();
                                          }
                                        }}
                                      >
                                        Tamam
                                      </Button>
                                    </Box>
                                  ))
                          }
                        >
                          Choose
                        </Button>
                      </Box>
                    ) : undefined
                  )}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction={loading ? "row" : "column"}
          align={loading ? "center" : undefined}
          justify={loading ? "space-between" : undefined}
        >
          {loading ? (
            <Box
              flex={1}
              align="center"
              justify="center"
              direction="row"
              display="flex"
            >
              <Box width={42} height={42}>
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            </Box>
          ) : (
            <>
              {entities.length > 0 ? (
                entities.map((entity) => (
                  <Box
                    key={entity.uuid}
                    mode="padding"
                    top={8}
                    bottom={8}
                    display="flex"
                    flex={1}
                    align="center"
                    direction="row"
                  >
                    {settings.columns.map((column) =>
                      column.type === "identifier" ? (
                        <Box
                          flex={1}
                          display="flex"
                          direction="column"
                          mode="padding"
                          right={16}
                        >
                          <Rule rule="Body" bottom={4}>
                            {column.value(entity)}
                          </Rule>
                          <Rule rule="Small" opacity={0.5}>
                            {entity.uuid}
                          </Rule>
                        </Box>
                      ) : undefined
                    )}
                    <Box display="inline-flex" align="center">
                      <Button
                        type="disabled"
                        size="small"
                        press={
                          !changed
                            ? async () => {
                                let parameters = {};
                                parameters[rules.api] = entity.connector;
                                await API.request(
                                  "/@" + rules.api + "/delete",
                                  parameters
                                );
                                if (reload) {
                                  await reload();
                                }
                              }
                            : () =>
                                Toast((toast) => (
                                  <Box display="flex" align="flex-start">
                                    <Box mode="padding" right={12} flex={1}>
                                      Kaydedilmemiş değişiklikler kaybolacaktır.
                                    </Box>
                                    <Button
                                      type="dark"
                                      press={async () => {
                                        let parameters = {};
                                        parameters[rules.api] =
                                          entity.connector;
                                        await API.request(
                                          "/@" + rules.api + "/delete",
                                          parameters
                                        );
                                        if (reload) {
                                          await reload();
                                        }
                                      }}
                                    >
                                      Tamam
                                    </Button>
                                  </Box>
                                ))
                        }
                      >
                        Delete
                      </Button>
                      <Box left={12} display="block">
                        <Button
                          type="dark"
                          size="small"
                          press={
                            !changed
                              ? () =>
                                  Pages.screen(
                                    "@entity/" + rules.connect,
                                    entity.uuid
                                  )
                              : () =>
                                  Toast((toast) => (
                                    <Box display="flex" align="flex-start">
                                      <Box mode="padding" right={12} flex={1}>
                                        Kaydedilmemiş değişiklikler
                                        kaybolacaktır.
                                      </Box>
                                      <Button
                                        type="dark"
                                        press={() => {
                                          Toast.dismiss(toast.id);
                                          Pages.screen(
                                            "@entity/" + rules.connect,
                                            entity.uuid
                                          );
                                        }}
                                      >
                                        Tamam
                                      </Button>
                                    </Box>
                                  ))
                          }
                        >
                          Details
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <>
                  <Box
                    mode="margin"
                    radius={8}
                    bottom={8}
                    top={8}
                    height={38}
                    border="1.5px dashed #f7f7f7"
                  ></Box>
                  <Box
                    mode="margin"
                    radius={8}
                    bottom={8}
                    top={8}
                    height={38}
                    border="1.5px dashed #f7f7f7"
                  ></Box>
                </>
              )}
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Component;
