import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entity(
      "experts",
      (entity) => entity.parameters.label,
      ["update", "delete"],
      [
        Assistant.block.attachment("attachment", "Attachment", (entity) => {
          return {
            maximum: 1,
            mode: "single",
            types: ["image/jpeg"],
            display: true,
          };
        }),
        Assistant.block.string("name", "Name"),
        Assistant.block.string("profession", "Profession"),
        Assistant.block.string("city", "city"),
        Assistant.block.string("district", "District"),
        Assistant.block.string("phone", "Phone", () => {
          return {
            mask: "+99 999 999 9999",
            display: true,
          };
        }),
        Assistant.block.string("email", "Email"),
      ]
    );
  },
};

export default Bite;
