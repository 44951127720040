import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Mobile,
  Rule,
  Line,
  Button,
  Input,
  Icon,
  Choose,
  QuickEntity,
} from "../Bitter";
import { Colors } from "../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { quicks } = this.props.redux;

    return (
      <>
        <Mobile
          render={(mobile) =>
            quicks.map((quick, s) =>
              s === quicks.length - 1 ? (
                <Box
                  key={quick.uuid}
                  display="block"
                  mode="position"
                  position="fixed"
                  with="100%"
                  height="100%"
                  color="rgba(10,15,13,0.30)"
                  display="flex"
                  align="center"
                  justify="flex-end"
                  direction="column"
                  overflow="auto"
                  style={{ boxSizing: "border-box", padding: 24 }}
                  layer={7}
                >
                  <QuickEntity
                    pending={quick.loading}
                    close={() => {
                      if (quick.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(quicks);
                      temporary.map((t, tt) => {
                        if (t.uuid === quick.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value: false,
                          };
                        }
                      });
                      this.props.core("quicks", temporary);
                    }}
                    buttons={quick.buttons || []}
                    settings={quick.settings}
                    entity={quick.uuid}
                    change={(value) => {
                      if (quick.loading) {
                        return false;
                      }
                      let temporary = Lodash.clone(quicks);
                      temporary.map((t, tt) => {
                        if (t.uuid === quick.uuid) {
                          temporary[tt].loading = true;
                          temporary[tt].result = {
                            value,
                          };
                        }
                      });
                      this.props.core("quicks", temporary);
                    }}
                  />
                </Box>
              ) : undefined
            )
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
