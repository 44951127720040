import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    const menus = [];

    const levels = {
      admin: [],
      permission: [
        Assistant.menu.bitter(
          "Appointments",
          "appointments",
          "https://img.icons8.com/color-glass/96/000000/appointment-reminders.png"
        ),
        Assistant.menu.bitter(
          "Collaborations",
          "collaborations",
          "https://img.icons8.com/color-glass/96/000000/employee-card.png"
        ),
        Assistant.menu.bitter(
          "Posts",
          "posts",
          "https://img.icons8.com/color-glass/96/000000/paper-plane.png"
        ),
        Assistant.menu.bitter(
          "Statics",
          "statics",
          "https://img.icons8.com/color-glass/96/paper.png"
        ),
        Assistant.menu.bitter(
          "Sliders",
          "sliders",
          "https://img.icons8.com/color-glass/96/image.png"
        ),
        Assistant.menu.bitter(
          "Experts",
          "experts",
          "https://img.icons8.com/color-glass/96/000000/doctor-male.png"
        ),
        Assistant.menu.bitter(
          "Clinics",
          "clinics",
          "https://img.icons8.com/color-glass/96/000000/clinic.png"
        ),
        Assistant.menu.bitter(
          "Users",
          "users",
          "https://img.icons8.com/color-glass/96/000000/user-group-man-man.png"
        ),
        Assistant.menu.bitter(
          "Permissions",
          "permissions",
          "https://img.icons8.com/color-glass/96/000000/question-shield.png"
        ),
        Assistant.menu.bitter(
          "Attachments",
          "attachments",
          "https://img.icons8.com/color-glass/96/000000/attach.png"
        ),
      ],
    };

    levels.permission.map((level) => {
      if (Permissions.permission("menu:" + level.value)) {
        menus.push(level);
      }
    });

    levels.admin.map((level) => {
      if (Permissions.permission("*")) {
        menus.push(level);
      }
    });

    return menus;
  },
};

export default Bite;
