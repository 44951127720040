import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search, Icon } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";
import Lodash from "lodash";

const Check = ({ press, active }) => (
  <Box
    width={24}
    height={24}
    color={active ? Colors.primary : Colors.white}
    display="inline-flex"
    align="center"
    justify="center"
    radius={4}
    border={!active ? "2px solid #F7F7F7" : undefined}
  >
    {active ? <Icon size={20} icon="check" color={Colors.white} /> : undefined}
  </Box>
);

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      selected: [],
    };
  }

  render() {
    const { search, selected } = this.state;
    const {
      entities,
      label,
      identifier,
      change,
      changed,
      cancel,
      working,
    } = this.props;

    return (
      <>
        <Box
          mode="padding"
          all={16}
          radius={8}
          shadow="small"
          color={Colors.white}
          width={512}
        >
          <Box
            display="flex"
            align="flex-start"
            justify="space-between"
            bottom={12}
          >
            <Rule rule="Title">Choose</Rule>
            <Box direction="row" display="inline-flex">
              <Box left={12}>
                <Button
                  type="dark"
                  press={() => {
                    if (change) {
                      let list = [];

                      entities.map((e) => {
                        if (selected.includes(e[identifier])) {
                          list.push(e);
                        }
                      });

                      change(list);
                    }
                  }}
                  loading={working}
                >
                  Okay
                </Button>
              </Box>
              <Box left={12}>
                <Button
                  type="white"
                  press={() => {
                    if (cancel) {
                      cancel();
                    }
                  }}
                  loading={working}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
          <Box bottom={12}>
            <Search
              value={search}
              change={(search) => this.setState({ search })}
            />
          </Box>
          {entities.length > 0 ? (
            <Box
              radius={8}
              shadow="medium"
              mode="padding"
              left={16}
              top={8}
              bottom={8}
              right={16}
              style={{ maxHeight: 512, overflowY: "auto" }}
            >
              {entities.map((entity) => {
                if (search.length > 0) {
                  if (
                    !JSON.stringify(entity)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return undefined;
                  }
                }

                return (
                  <Box
                    key={entity[identifier]}
                    mode="padding"
                    top={8}
                    bottom={8}
                    display="flex"
                    align="center"
                    direction="row"
                    press={() => {
                      let temporary = Lodash.clone(selected);
                      if (temporary.includes(entity[identifier])) {
                        Lodash.remove(temporary, function (e) {
                          return e === entity[identifier];
                        });
                        this.setState({ selected: temporary });
                      } else {
                        temporary.push(entity[identifier]);
                        this.setState({ selected: temporary });
                      }
                    }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      direction="column"
                      mode="padding"
                      right={16}
                    >
                      <Rule rule="Body" bottom={4}>
                        {label ? label(entity) : entity[identifier]}
                      </Rule>
                      <Rule rule="Small" opacity={0.5}>
                        {entity[identifier]}
                      </Rule>
                    </Box>
                    <Check active={selected.includes(entity[identifier])} />
                  </Box>
                );
              })}
            </Box>
          ) : undefined}
        </Box>
      </>
    );
  }
}

export default Component;
