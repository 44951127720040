import React, { useState } from "react";

import { Box, Text, Icon, Rule } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import BarLoader from "react-spinners/BarLoader";

const Component = (props) => {
  const {
    size = "medium",
    type = "dark",
    press,
    loading,
    icon,
    left,
    right,
    top,
    bottom,
    feather,
    drop = false,
  } = props;

  const HEIGHTS = {
    small: 24,
    medium: 32,
    large: 40,
  };
  const SPACINGS = {
    small: 8,
    medium: 12,
    large: 12,
  };
  const COLORS = {
    white: Colors.black,
    disabled: Colors.black,
    dark: Colors.white,
    icon: Colors.black,
    download: Colors.white,
    share: Colors.white,
    automate: Colors.black,
  };
  const BACKGROUNDS = {
    white: Colors.white,
    disabled: "#F7F7F7",
    dark: Colors.black,
    icon: "#F7F7F7",
    download: "#2a9d8f",
    share: "#219ebc",
    automate: "#d9ed92",
  };
  const BORDERS = {
    white: "1px solid #F7F7F7",
    disabled: "",
    dark: "",
    icon: "",
    download: "",
    share: "",
    automate: "",
  };

  const [open, setOpen] = useState(false);

  return (
    <Box position="relative" styling="--button">
      {drop ? (
        drop.length > 0 && open ? (
          <Box
            position="absolute"
            mode="position"
            top="calc(100% + 4px)"
            right={0}
            left="unset"
            bottom="unset"
            layer={2}
          >
            <Box
              width={256}
              all={12}
              mode="padding"
              shadow="large"
              radius={8}
              color={Colors.white}
            >
              {drop.map((menu) => (
                <Box
                  mode="padding"
                  display="flex"
                  align="center"
                  press={() => {
                    setOpen(false);
                    if (menu.callback) {
                      menu.callback();
                    }
                  }}
                >
                  <Box right={4} width={16} height={16}>
                    {menu.icon ? (
                      Functions.url(menu.icon) ? (
                        <img
                          width={16}
                          height={16}
                          alt={menu.icon}
                          src={menu.icon}
                        />
                      ) : (
                        <Icon color={COLORS[type]} icon={menu.icon} size={16} />
                      )
                    ) : undefined}
                  </Box>
                  <Rule rule="Small" family="Programme Regular">
                    {menu.label}
                  </Rule>
                </Box>
              ))}
            </Box>
          </Box>
        ) : open ? (
          <Box
            position="absolute"
            mode="position"
            top="calc(100% + 4px)"
            right={0}
            left="unset"
            bottom="unset"
            layer={2}
          >
            <Box
              width={256}
              all={12}
              mode="padding"
              shadow="large"
              radius={8}
              color={Colors.white}
            >
              <Box
                mode="padding"
                display="flex"
                align="center"
                press={() => {
                  setOpen(false);
                }}
              >
                <Box right={4} width={16} height={16}>
                  <img
                    width={16}
                    height={16}
                    alt="https://img.icons8.com/color-glass/2x/question-mark.png"
                    src="https://img.icons8.com/color-glass/2x/question-mark.png"
                  />
                </Box>
                <Rule rule="Small" family="Programme Regular" opacity={0.5}>
                  No Options
                </Rule>
              </Box>
            </Box>
          </Box>
        ) : undefined
      ) : undefined}
      {left || right || top || bottom ? (
        <Box mode="margin" top={top} left={left} right={right} bottom={bottom}>
          <Box
            color={BACKGROUNDS[type]}
            height={HEIGHTS[size]}
            left={SPACINGS[size]}
            right={SPACINGS[size]}
            border={BORDERS[type]}
            align="center"
            justify="center"
            display="inline-flex"
            mode="padding"
            direction="row"
            radius={2}
            press={loading ? undefined : drop ? () => setOpen(!open) : press}
          >
            {loading ? (
              <BarLoader
                height={6}
                width={
                  typeof props.children === "string"
                    ? props.children.length * 6
                    : 64
                }
                color={COLORS[type]}
                loading={true}
              />
            ) : (
              <>
                {icon ? (
                  <Icon
                    feather={feather}
                    color={COLORS[type]}
                    icon={icon}
                    size={16}
                    right={props.children ? 8 : 0}
                  />
                ) : undefined}
                <Text
                  color={COLORS[type]}
                  size={14}
                  family="Programme Bold"
                  line={HEIGHTS[size]}
                >
                  {props.children}
                </Text>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          color={BACKGROUNDS[type]}
          height={HEIGHTS[size]}
          left={SPACINGS[size]}
          right={SPACINGS[size]}
          border={BORDERS[type]}
          align="center"
          justify="center"
          display="inline-flex"
          mode="padding"
          direction="row"
          radius={2}
          press={loading ? undefined : drop ? () => setOpen(!open) : press}
          {...props}
        >
          {loading ? (
            <BarLoader
              height={6}
              width={
                typeof props.children === "string"
                  ? props.children.length * 6
                  : 64
              }
              color={COLORS[type]}
              loading={true}
            />
          ) : (
            <>
              {icon ? (
                <Icon
                  feather={feather}
                  color={COLORS[type]}
                  icon={icon}
                  size={16}
                  right={props.children ? 8 : 0}
                />
              ) : undefined}
              <Text
                color={COLORS[type]}
                size={14}
                family="Programme Bold"
                line={HEIGHTS[size]}
              >
                {props.children}
              </Text>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Component;
