import React from "react";
import { connect } from "react-redux";
import { Box, Icon, Rule, Button, Line, D1, Input, Selector } from "../Bitter";
import { Functions, Colors, Resources, Images } from "../Helpers";
import { Pages, API, Auth } from "../Modules";
import { Proforma } from "../Project";
import Lodash from "lodash";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      forgot: false,
      user: false,
      account: false,
      welcome: false,
      loading: false,
      username: "",
      password: "",
    };
  }

  login = async () => {
    const { loading, username, password } = this.state;
    if (loading) {
      return false;
    }

    this.setState({ loading: true });
    const login = await API.request("/auth/login", { username, password });
    if (login.success) {
      this.setState({ user: login.data, welcome: true });
    }
    this.setState({ loading: false });
  };

  render() {
    const {
      forgot,
      welcome,
      user,
      loading,
      username,
      password,
      account,
    } = this.state;
    const first = Pages.first();

    const accounts = [];
    if (user) {
      user.accounts.map((account) => {
        accounts.push({
          name: user.parameters.name,
          label: account.permissions.parameters.label,
          uuid: account.uuid,
          data: account,
        });
      });
    }

    const { expand } = this.props.redux;

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <Box
          width="100%"
          style={{ maxWidth: !expand ? undefined : 560 }}
          mode="margin"
          left="auto"
          right="auto"
          height="100%"
          display="flex"
          direction="column"
          justify="center"
        >
          {welcome ? (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              <Rule rule="Header" bottom={12} display="block">
                Welcome
                {user.parameters.name ? " " + user.parameters.name + "," : ","}
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                You can use one of your accounts given by {Resources.company}.
              </Rule>
              <Box bottom={24}>
                <Selector
                  title="Accounts"
                  entity={account}
                  change={({ data: account }) => this.setState({ account })}
                  entities={accounts}
                />
              </Box>
              <Button
                disabled={true}
                type={account ? "dark" : "disabled"}
                press={() => {
                  if (account) {
                    Auth.login(user, account);
                  }
                }}
              >
                Login
              </Button>
            </Box>
          ) : forgot ? (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              <Rule rule="Header" bottom={12} display="block">
                Support
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                If you forgot your password, you need to contact{" "}
                {Resources.company}.
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                For support, you can use the contact details down below.
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                {Resources.support}
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                {Resources.phone}
              </Rule>
              <Button
                type="disabled"
                press={() => this.setState({ forgot: false })}
              >
                Back
              </Button>
            </Box>
          ) : (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              <Rule rule="Header" bottom={12} display="block">
                Welcome to {Resources.app}
              </Rule>
              <Rule rule="Body" bottom={24} opacity={0.5} display="block">
                To login, use your account information given by{" "}
                {Resources.company}.
              </Rule>
              <Box bottom={24}>
                <Rule rule="Body" display="block" bottom={4}>
                  Email / Username
                </Rule>
                <Input
                  value={username}
                  change={(username) => this.setState({ username })}
                />
              </Box>
              <Box bottom={24}>
                <Rule rule="Body" display="block" bottom={4}>
                  Password
                </Rule>
                <Input
                  type="password"
                  value={password}
                  change={(password) => this.setState({ password })}
                />
              </Box>
              <Box bottom={0} justify="space-between" display="flex">
                <Button type="dark" loading={loading} press={this.login}>
                  Login
                </Button>
                <Rule
                  rule="Body"
                  display="block"
                  bottom={4}
                  loading={loading}
                  press={() => this.setState({ forgot: true })}
                >
                  Forgot Password
                </Rule>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          align="center"
          justify="center"
          mode="padding"
          all={24}
        >
          <Rule right={12}>Powered By</Rule>
          <img
            src={Images.src("assets/i/power.svg")}
            width="auto"
            display="block"
            height="24"
            width="24"
            alt={Resources.app}
          />
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
