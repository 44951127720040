import React from "react";
import { connect } from "react-redux";
import { Box, Text, Avatar, Rule, Badge, Line, Mobile, Icon } from "../Bitter";
import { Resources, Colors, Functions, Images } from "../Helpers";
import { Pages, Auth } from "../Modules";
import { System } from "../System";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      screen,
      user,
      account,
      sidebar,
      expand,
      professional,
      dark,
    } = this.props.redux;

    return (
      <Mobile
        render={(mobile) => {
          if (mobile && !sidebar) {
            return undefined;
          }

          let additions = {};

          if (mobile) {
            additions = {
              position: "absolute",
              top: 0,
              left: 0,
              mode: "position",
              color: Colors.white,
              layer: 2,
            };
          }

          return (
            <Box
              width={!expand && !mobile ? 72 : mobile ? "100%" : 320}
              height="100%"
              shadow={mobile ? undefined : "small"}
              display="flex"
              direction="column"
              {...additions}
              style={{ minWidth: expand && !mobile ? 320 : undefined }}
            >
              <Box mode="padding" all={24}>
                <Box
                  align="flex-start"
                  display="flex"
                  align="center"
                  justify="space-between"
                >
                  <img
                    src={Images.src(
                      !expand && !mobile
                        ? "assets/i/ico.svg"
                        : "assets/i/icon.svg"
                    )}
                    width="auto"
                    display="block"
                    height="24"
                    width={!expand && !mobile ? "24" : undefined}
                    alt={Resources.app}
                    className="--keep"
                  />
                </Box>
              </Box>
              <Line />
              {!expand && !mobile ? undefined : (
                <>
                  <Box
                    mode="padding"
                    all={24}
                    display="flex"
                    align="center"
                    justify="space-between"
                  >
                    <Box align="flex-start" display="flex">
                      <Avatar />
                      <Box left={12} right={12}>
                        <Box align="flex-start" display="flex">
                          <Rule rule="Body" right={8}>
                            {user.parameters.username}
                          </Rule>
                          <Badge>{account.permissions.parameters.label}</Badge>
                        </Box>
                        <Rule rule="Small" opacity={0.5}>
                          {user.parameters.email}
                        </Rule>
                      </Box>
                    </Box>
                  </Box>
                  <Line />
                </>
              )}
              <Box mode="padding" bottom={24} flex={1} overflow="auto">
                {System.system().menu.map((menu) => (
                  <Box
                    mode="padding"
                    top={24}
                    left={24}
                    right={24}
                    display="flex"
                    align="center"
                    opacity={
                      menu.custom === true
                        ? screen === menu.value
                          ? 1
                          : 0.5
                        : screen === "@entities/" + menu.value
                        ? 1
                        : 0.5
                    }
                    press={() =>
                      menu.custom === true
                        ? Pages.screen(menu.value)
                        : Pages.screen("@entities/" + menu.value)
                    }
                  >
                    <Box
                      right={!expand && !mobile ? 0 : 12}
                      width={24}
                      height={24}
                    >
                      {menu.icon ? (
                        Functions.url(menu.icon) ? (
                          <img
                            width={24}
                            height={24}
                            alt={menu.icon}
                            src={menu.icon}
                          />
                        ) : (
                          <Icon icon={menu.icon} size={16} />
                        )
                      ) : undefined}
                    </Box>
                    {!expand && !mobile ? undefined : menu.label}
                  </Box>
                ))}
              </Box>
              <Box>
                <Line />
                <Box
                  mode="padding"
                  all={24}
                  display="flex"
                  align="center"
                  justify="space-between"
                >
                  {expand && !mobile ? (
                    <Box
                      display="inline-flex"
                      align="center"
                      direction="row"
                      press={() => Auth.logout()}
                      opacity={0.5}
                    >
                      <Box
                        width={24}
                        height={24}
                        display="inline-flex"
                        align="center"
                        justify="center"
                        right={12}
                      >
                        <Icon size={20} icon="log-out" right={12} />
                      </Box>
                      Logout
                    </Box>
                  ) : undefined}
                  <Box direction="row" display="inline-flex">
                    {expand && !mobile ? (
                      <Box
                        width={24}
                        height={24}
                        display="inline-flex"
                        align="center"
                        justify="center"
                        opacity={dark ? 1 : 0.5}
                        right={12}
                      >
                        <Icon
                          icon={dark ? "moon" : "sun"}
                          color={dark ? Colors.primary : undefined}
                          size={20}
                          press={() => {
                            if (dark) {
                              document
                                .getElementsByTagName("html")[0]
                                .setAttribute("theme", "light");
                            } else {
                              document
                                .getElementsByTagName("html")[0]
                                .setAttribute("theme", "dark");
                            }

                            this.props.core("dark", !dark);
                          }}
                        />
                      </Box>
                    ) : undefined}
                    {expand && !mobile ? (
                      <Box
                        width={24}
                        height={24}
                        display="inline-flex"
                        align="center"
                        justify="center"
                        opacity={professional ? 1 : 0.5}
                        right={12}
                      >
                        <Icon
                          icon="command"
                          color={professional ? Colors.primary : undefined}
                          size={20}
                          press={() =>
                            this.props.core("professional", !professional)
                          }
                        />
                      </Box>
                    ) : undefined}
                    <Box
                      width={24}
                      height={24}
                      display="inline-flex"
                      align="center"
                      justify="center"
                      opacity={0.5}
                    >
                      <Icon
                        icon={expand ? "minimize" : "maximize"}
                        size={20}
                        press={() => this.props.core("expand", !expand)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
