import { Store } from "../Modules";

class Permissions {
  static permission(permission) {
    const { account } = Store.getState();
    const { permissions } = account.permissions.parameters;

    if (permissions.includes("*")) {
      return true;
    }

    if (permissions.includes(permission)) {
      return true;
    }

    return false;
  }

  static account() {
    const { account } = Store.getState();
    return account.uuid;
  }

  static user() {
    const { user } = Store.getState();
    return user.uuid;
  }

  static name() {
    const { user } = Store.getState();
    return user.parameters.name;
  }

  static role(role, temporary = false) {
    const { account } = Store.getState();
    const { permissions } = account.permissions.parameters;

    if (temporary) {
      if (temporary.includes("*")) {
        return true;
      }
      if (temporary.includes("role:" + role)) {
        return true;
      }
    } else {
      if (permissions.includes("*")) {
        return true;
      }
      if (permissions.includes("role:" + role)) {
        return true;
      }
    }

    return false;
  }
}

export default Permissions;
