import React from "react";
import { connect } from "react-redux";

import { Resources } from "../../Helpers";

import EditorJS from "react-editor-js";
import Paragraph from "@editorjs/paragraph";
import Image from "@editorjs/image";
import Header from "@editorjs/header";
import ColorPlugin from "editorjs-text-color-plugin";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    const { admin } = this.props.redux;

    this.state = {
      tools: {
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: Resources.url + "/editor/image",
            },
          },
        },
        color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: ["#000000", "#ffffff", "#029ED0"],
            defaultColor: "#FF1300",
            type: "text",
          },
        },
        marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: "#FFBF00",
            type: "marker",
          },
        },
        header: Header,
      },
    };
  }

  async componentDidMount() {
    const { admin } = this.props.redux;
    this.setState({
      tools: {
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: Resources.url + "/editor/image",
            },
            additionalRequestHeaders: {
              Authorization: admin,
              Platform: "web",
              Device: "ADMIN",
            },
          },
        },
        color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: ["#000000", "#ffffff", "#029ED0"],
            defaultColor: "#FF1300",
            type: "text",
          },
        },
        marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: "#FFBF00",
            type: "marker",
          },
        },
        header: Header,
      },
    });
  }

  render() {
    const { value, change } = this.props;
    const { tools } = this.state;

    return (
      <EditorJS
        data={value}
        value={value}
        onChange={change ? (api, data) => change(data) : undefined}
        tools={tools}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
