import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entity(
      "sliders",
      (entity) => entity.parameters.title,
      ["update", "delete"],
      [
        Assistant.block.attachment("attachment", "Attachment", (entity) => {
          return {
            maximum: 1,
            mode: "single",
            types: ["image/jpeg"],
            display: true,
          };
        }),
        Assistant.block.attachment("mobile", "Mobile Attachment", (entity) => {
          return {
            maximum: 1,
            mode: "single",
            types: ["image/jpeg"],
            display: true,
          };
        }),
        Assistant.block.string("title", "Title"),
        Assistant.block.string("slug", "Slug"),
        Assistant.block.string("explanation", "Explanation"),
        Assistant.block.editor("content", "Content"),
      ]
    );
  },
};

export default Bite;
