import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule } from "../../Bitter";
import InputMask from "react-input-mask";

const Component = ({
  value,
  change,
  type = "text",
  placeholder,
  bottom = 0,
  label = false,
  size = "medium",
  rules = {},
}) => {
  const HEIGHT = {
    medium: 40,
    small: 32,
  };

  const PADDING = {
    medium: 12,
    small: 8,
  };

  const RULE = {
    medium: undefined,
    small: 12,
  };

  const { mask = false } = rules;

  return (
    <Box>
      {label ? (
        <Rule rule="Body" display="block" bottom={4} size={RULE[size]}>
          {label}
        </Rule>
      ) : undefined}

      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
        >
          {(props) => (
            <input
              placeholder={placeholder}
              type={type}
              style={{
                height: HEIGHT[size],
                border: "1.5px solid #F7F7F7",
                borderRadius: 2,
                boxSizing: "border-box",
                width: "100%",
                display: "block",
                paddingLeft: PADDING[size],
                paddingRight: PADDING[size],
                fontSize: 14,
                fontFamily: "Programme Light",
                color: Colors.black,
                outline: "none",
                background: "none",
                backgroundColor: "none",
                marginBottom: bottom,
              }}
              value={props.value}
              onChange={props.onChange}
            />
          )}
        </InputMask>
      ) : (
        <input
          placeholder={placeholder}
          type={type}
          style={{
            height: HEIGHT[size],
            border: "1.5px solid #F7F7F7",
            borderRadius: 2,
            boxSizing: "border-box",
            width: "100%",
            display: "block",
            paddingLeft: PADDING[size],
            paddingRight: PADDING[size],
            fontSize: 14,
            fontFamily: "Programme Light",
            color: Colors.black,
            outline: "none",
            background: "none",
            backgroundColor: "none",
            marginBottom: bottom,
          }}
          value={value}
          onChange={(event) => (change ? change(event.target.value) : {})}
        />
      )}
    </Box>
  );
};

export default Component;
