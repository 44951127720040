import { Pages, Store } from "../Modules";

class Auth {
  static async auth() {
    try {
      const user = localStorage.getItem(":user");
      const account = localStorage.getItem(":account");
      if (user && account) {
        this.login(JSON.parse(user), JSON.parse(account));
      }
    } catch (Error) {
      console.error(Error);
    }
  }

  static login(user, account) {
    Store.dispatch({ type: "CORE", key: "account", value: account });
    Store.dispatch({ type: "CORE", key: "user", value: user });
    localStorage.clear();
    localStorage.setItem(":user", JSON.stringify(user));
    localStorage.setItem(":account", JSON.stringify(account));
  }

  static logout() {
    localStorage.clear();
    Pages.screen("");
    Store.dispatch({ type: "CORE", key: "account", value: false });
    Store.dispatch({ type: "CORE", key: "user", value: false });
  }
}

export default Auth;
