import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entity(
      "attachments",
      (entity) => entity.parameters.name,
      ["update", "delete"],
      [
        Assistant.block.attachment(
          "uuid",
          "Preview",
          (entity) => {
            return {
              editable: false,
              maximum: 1,
              mode: "single",
              display: true,
            };
          },
          false,
          (entity) => entity.uuid
        ),
        Assistant.block.string("name", "Name"),
        Assistant.block.text(
          "date",
          "Date",
          () => {
            return { display: true };
          },
          (entity) =>
            Moment(entity.parameters.date).format("D MMMM YYYY HH:mm")
        ),
        Assistant.block.text(
          "size",
          "Size",
          () => {
            return { display: true };
          },
          (entity) => Functions.bytes(entity.parameters.size)
        ),
        Assistant.block.text(
          "extension",
          "Extension",
          () => {
            return { display: true };
          },
          (entity) => entity.parameters.extension.toUpperCase()
        ),
        Assistant.block.text("type", "Type", () => {
          return { display: true };
        }),
      ]
    );
  },
};

export default Bite;
