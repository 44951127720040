import Electron from "is-electron";

const R = {
  t: (value) => {
    return { type: "title", value };
  },
  l: (value) => {
    return { type: "large", value };
  },
  b: (value) => {
    return { type: "badge", value };
  },
  p: (value) => {
    return { type: "paragraph", value };
  },
  s: (value) => {
    return { type: "small", value };
  },
  v: (value) => {
    return { type: "void", value };
  },
};

const Resources = {
  url: window.location.hostname.split(".").includes("localhost")
    ? Electron()
      ? "https://backupturkiye.com/api"
      : "https://backupturkiye.com/api"
    : "https://backupturkiye.com/api",
  dashboard: window.location.hostname.split(".").includes("localhost")
    ? Electron()
      ? "https://dashboard.backupturkiye.com"
      : "http://localhost:3000"
    : "https://dashboard.backupturkiye.com",
  admin: {
    name: "Julian Gumenita",
    email: "julian@gumenita.com",
    badge: "Developer",
  },
  app: "Bitter",
  company: "Theraphy",
  legal: "Theraphy",
  support: "destek@theraphy.gumenita.com",
  phone: "0 850 000 0 000",
  drift: false, //"279ucpu36vix"
  documentation: {},
};

export default Resources;
