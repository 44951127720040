import Assistant from "./Assistant";
import M from "./Minifier";
import Permissions from "./Permissions";
import { Functions, Resources } from "../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../Modules";
import { Documentation, Box, Button } from "../Bitter";
import { Custom, ProformaDocument, Proforma } from "../Project";
import { ProformaSequence } from "../Sequence";

import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

import {
  MenuBite,
  MobileBite,
  DefinitionsBite,
  InitialBite,
  EntitiesAccountsBite,
  EntitiesAttachmentsBite,
  EntitiesPermissionsBite,
  EntitiesAppointmentsBite,
  EntitiesCollaborationsBite,
  EntitiesPostsBite,
  EntitiesExpertsBite,
  EntitiesClinicsBite,
  EntitiesUsersBite,
  EntitiesStaticsBite,
  EntitiesSlidersBite,
  EntityAccountsBite,
  EntityAttachmentsBite,
  EntityPermissionsBite,
  EntityUsersBite,
  EntityAppointmentsBite,
  EntityCollaborationsBite,
  EntityPostsBite,
  EntityExpertsBite,
  EntityClinicsBite,
  EntityStaticsBite,
  EntitySlidersBite,
} from "./Bites";

class System {
  static menu() {
    return MenuBite.bite();
  }

  static mobile() {
    return MobileBite.bite();
  }

  static system() {
    return {
      initial: InitialBite.bite(),
      definitions: DefinitionsBite.bite(),
      menu: this.menu(),
      mobile: this.mobile(),
      entities: {
        attachments: EntitiesAttachmentsBite.bite(),
        users: EntitiesUsersBite.bite(),
        accounts: EntitiesAccountsBite.bite(),
        permissions: EntitiesPermissionsBite.bite(),
        appointments: EntitiesAppointmentsBite.bite(),
        collaborations: EntitiesCollaborationsBite.bite(),
        posts: EntitiesPostsBite.bite(),
        experts: EntitiesExpertsBite.bite(),
        clinics: EntitiesClinicsBite.bite(),
        statics: EntitiesStaticsBite.bite(),
        sliders: EntitiesSlidersBite.bite(),
      },
      entity: {
        attachments: EntityAttachmentsBite.bite(),
        users: EntityUsersBite.bite(),
        accounts: EntityAccountsBite.bite(),
        permissions: EntityPermissionsBite.bite(),
        appointments: EntityAppointmentsBite.bite(),
        collaborations: EntityCollaborationsBite.bite(),
        posts: EntityPostsBite.bite(),
        experts: EntityExpertsBite.bite(),
        clinics: EntityClinicsBite.bite(),
        statics: EntityStaticsBite.bite(),
        sliders: EntitySlidersBite.bite(),
      },
      quick: {},
    };
  }
}

export default System;
