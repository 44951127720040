import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      choose: false,
      value: false,
      search: "",
    };
  }

  async componentDidMount() {
    const { value, options } = this.props;
    if (value !== undefined) {
      let entity = false;
      options.map((o) => {
        if (o.value === value) {
          entity = o;
        }
      });
      this.setState({ entity });
    }
  }

  render() {
    const { options, label, change } = this.props;
    const { entity, choose, search } = this.state;

    return (
      <>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.10)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{label}</Rule>
                <Box direction="row" display="inline-flex">
                  <Box left={12}>
                    <Button
                      type="white"
                      press={() => this.setState({ choose: false })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
              {options.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {options.map((element, e) => {
                    if (search.length > 0) {
                      if (
                        !JSON.stringify(element)
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return undefined;
                      }
                    }

                    return (
                      <Box
                        key={element.uuid || e}
                        mode="padding"
                        top={8}
                        bottom={8}
                        display="flex"
                        align="center"
                        direction="row"
                      >
                        <Box
                          flex={1}
                          display="flex"
                          direction="column"
                          mode="padding"
                          right={16}
                        >
                          {element.label ? (
                            <Rule rule="Body" bottom={element.value ? 4 : 0}>
                              {element.label}
                            </Rule>
                          ) : undefined}
                          {typeof element.value === "string" ? (
                            <Rule rule="Small" opacity={0.5}>
                              {element.value}
                            </Rule>
                          ) : (
                            <Rule rule="Small" opacity={0.5}>
                              {label}
                            </Rule>
                          )}
                        </Box>
                        <Button
                          type="disabled"
                          size="small"
                          press={() => {
                            this.setState({ entity: element, choose: false });
                            if (change) {
                              change(element.value);
                            }
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction="row"
          align="center"
          justify="space-between"
        >
          <Box>
            {entity ? (
              <>
                <Rule rule="Body" display="block" bottom={4}>
                  {entity.label}
                </Rule>
                <Rule rule="Small" display="block" opacity={0.5}>
                  {options.length} Options
                </Rule>
              </>
            ) : (
              <>
                <Rule rule="Body" display="block" bottom={4}>
                  Not Selected
                </Rule>
              </>
            )}
          </Box>
          <Box display="flex" align="center" direction="row">
            <Box left={12}>
              <Button
                size="small"
                press={() => this.setState({ choose: true })}
                type="dark"
              >
                Choose
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default Component;
