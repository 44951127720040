import React from "react";
import { connect } from "react-redux";
import { Box, Mobile, Rule, Line, Button, Input } from "../Bitter";
import { Colors } from "../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { popups } = this.props.redux;

    return (
      <>
        <Mobile
          render={(mobile) =>
            popups.map((popup, p) =>
              p === popups.length - 1 ? (
                <Box
                  key={popup.uuid}
                  display="block"
                  mode="position"
                  position="fixed"
                  with="100%"
                  height="100%"
                  color="rgba(10,15,13,0.30)"
                  display="flex"
                  align="center"
                  justify="flex-start"
                  direction="column"
                  overflow="auto"
                  style={{ boxSizing: "border-box", padding: 24 }}
                  layer={4}
                >
                  <Box
                    color="white"
                    radius={8}
                    width="100%"
                    style={{ maxWidth: 500 }}
                  >
                    {popup.configuration.title || popup.configuration.cancel ? (
                      <>
                        <Box
                          all={24}
                          mode="padding"
                          display="flex"
                          direction="row"
                          justify="space-between"
                          align="center"
                        >
                          <Rule rule="Title" color={Colors.black}>
                            {popup.configuration.title}
                          </Rule>
                          {popup.configuration.cancel ? (
                            <Button
                              loading={popup.loading}
                              type="white"
                              press={() => {
                                if (popup.loading) {
                                  return false;
                                }
                                let temporary = Lodash.clone(popups);
                                temporary.map((t, tt) => {
                                  if (t.uuid === popup.uuid) {
                                    temporary[tt].loading = true;
                                    temporary[tt].result = {
                                      value: false,
                                    };
                                  }
                                });
                                this.props.core("popups", temporary);
                              }}
                            >
                              {typeof popup.configuration.cancel === "string"
                                ? popup.configuration.cancel
                                : "Cancel"}
                            </Button>
                          ) : undefined}
                        </Box>
                        <Line />
                      </>
                    ) : undefined}
                    {popup.configuration.text ? (
                      typeof popup.configuration.text === "object" ? (
                        popup.configuration.text.map((text) => (
                          <>
                            <Box all={24} mode="padding">
                              <Rule rule="Body" color={Colors.black}>
                                {text}
                              </Rule>
                            </Box>
                            <Line />
                          </>
                        ))
                      ) : (
                        <>
                          <Box all={24} mode="padding">
                            <Rule rule="Body" color={Colors.black}>
                              {popup.configuration.text}
                            </Rule>
                          </Box>
                          <Line />
                        </>
                      )
                    ) : undefined}
                    {popup.configuration.mode === "input" ? (
                      <>
                        <Box all={24} mode="padding">
                          {Array.isArray(popup.configuration.input) ? (
                            popup.configuration.input.map((i, ii) => (
                              <>
                                <Rule rule="Body" display="block" bottom={4}>
                                  {i.label}
                                </Rule>
                                <Input
                                  bottom={
                                    ii < popup.configuration.input.length - 1
                                      ? 24
                                      : 0
                                  }
                                  value={
                                    popup.store
                                      ? popup.store[i.key]
                                        ? popup.store[i.key]
                                        : i.value
                                      : i.value
                                  }
                                  change={(value) => {
                                    if (i.change === "@store") {
                                      if (popup.loading) {
                                        return false;
                                      }
                                      let temporary = Lodash.clone(popups);
                                      temporary.map((t, tt) => {
                                        if (t.uuid === popup.uuid) {
                                          temporary[tt].store =
                                            temporary[tt].store || {};
                                          temporary[tt].store[i.key] = value;
                                        }
                                      });
                                      this.props.core("popups", temporary);
                                    }
                                  }}
                                />
                              </>
                            ))
                          ) : (
                            <>
                              <Rule rule="Body" display="block" bottom={4}>
                                {popup.configuration.input.label}
                              </Rule>
                              <Input
                                value={
                                  popup.store
                                    ? popup.store[popup.configuration.input.key]
                                      ? popup.store[
                                          popup.configuration.input.key
                                        ]
                                      : popup.configuration.input.value
                                    : popup.configuration.input.value
                                }
                                change={(value) => {
                                  if (
                                    popup.configuration.input.change ===
                                    "@store"
                                  ) {
                                    if (popup.loading) {
                                      return false;
                                    }
                                    let temporary = Lodash.clone(popups);
                                    temporary.map((t, tt) => {
                                      if (t.uuid === popup.uuid) {
                                        temporary[tt].store =
                                          temporary[tt].store || {};
                                        temporary[tt].store[
                                          popup.configuration.input.key
                                        ] = value;
                                      }
                                    });
                                    this.props.core("popups", temporary);
                                  }
                                }}
                              />
                            </>
                          )}
                        </Box>
                        <Line />
                      </>
                    ) : undefined}
                    {popup.configuration.actions.map((action) => (
                      <Box
                        all={24}
                        mode="padding"
                        display="flex"
                        direction="row"
                        justify="flex-end"
                        align="center"
                      >
                        <Button
                          type={action.type || "dark"}
                          loading={popup.loading}
                          press={() => {
                            if (popup.loading) {
                              return false;
                            }
                            let temporary = Lodash.clone(popups);
                            temporary.map((t, tt) => {
                              if (t.uuid === popup.uuid) {
                                if (t.configuration.mode === "input") {
                                  temporary[tt].loading = true;
                                  temporary[tt].result = {
                                    value: Array.isArray(
                                      popup.configuration.input
                                    )
                                      ? popup.store
                                      : popup.store[
                                          popup.configuration.input.key
                                        ] || popup.configuration.input.value,
                                  };
                                } else {
                                  temporary[tt].loading = true;
                                  temporary[tt].result = {
                                    value: action.value,
                                  };
                                }
                              }
                            });
                            this.props.core("popups", temporary);
                          }}
                        >
                          {action.text}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : undefined
            )
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
