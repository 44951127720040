import React from "react";
import { connect } from "react-redux";

class Component extends React.PureComponent {
  render() {
    const { render } = this.props;
    const { professional } = this.props.redux;
    return render ? render(professional) : null;
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
