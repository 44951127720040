class Debug {
  static set(worker) {
    if (worker) {
      try {
        window.debug = worker;
      } catch (Error) {
        console.error(Error);
      }
    }
    return false;
  }
}

export default Debug;
