import React from "react";
import Text from "./Text";

const Component = (props) => {
  const { rule } = props;
  return rule === "Display" ? (
    <Text size={36} family="Programme Bold" {...props} />
  ) : rule === "Header" ? (
    <Text size={24} family="Programme Bold" {...props} />
  ) : rule === "Title" ? (
    <Text size={20} family="Programme Bold" {...props} />
  ) : rule === "Large" ? (
    <Text size={20} family="Programme Regular" {...props} />
  ) : rule === "Bold" ? (
    <Text size={16} family="Programme Bold" {...props} />
  ) : rule === "Body" ? (
    <Text size={16} family="Programme Regular" {...props} />
  ) : rule === "Thin" ? (
    <Text size={16} family="Programme Light" {...props} />
  ) : rule === "Small" ? (
    <Text size={14} family="Programme Light" {...props} />
  ) : rule === "Tiny" ? (
    <Text size={12} family="Programme Light" {...props} />
  ) : rule === "Micro" ? (
    <Text size={9} family="Programme Light" {...props} />
  ) : (
    <Text family="Programme Regular" {...props} />
  );
};

export default Component;
