import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search, Icon } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";
import Lodash from "lodash";

const Check = ({ press, active }) => (
  <Box
    width={24}
    height={24}
    color={active ? Colors.primary : Colors.white}
    display="inline-flex"
    align="center"
    justify="center"
    radius={4}
    border={!active ? "2px solid #F7F7F7" : undefined}
  >
    {active ? <Icon size={20} icon="check" color={Colors.white} /> : undefined}
  </Box>
);

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entity: false,
      entities: [],
      choose: false,
      search: "",
      selected: [],
      timer: false,
    };

    this.timeout = false;
  }

  async componentDidMount() {
    const { value, rules, filter } = this.props;
    const entities = await API.request(
      "/@" + rules.api + "/read",
      filter ? { ...filter, limit: 50 } : { limit: 50 }
    );
    if (entities.success) {
      this.setState({ entities: entities.data });
      entities.data.map((entity) => {
        if (entity.uuid === value) {
          this.setState({ entity });
        }

        return true;
      });

      this.setState({ loading: false });
    }
  }

  search = async () => {
    const { value, rules, filter } = this.props;

    const { search } = this.state;

    let parameters = filter ? { ...filter, limit: 50 } : { limit: 50 };

    if (search.length > 0) {
      parameters.mode = "search";
      parameters.match = search;
    }

    const entities = await API.request("/@" + rules.api + "/read", parameters);
    if (entities.success) {
      this.setState({ entities: entities.data });
    }

    this.setState({ loading: false, timer: false });
    clearTimeout(this.timeout);
  };

  render() {
    const {
      entities,
      entity,
      loading,
      choose,
      search,
      selected,
      timer,
    } = this.state;
    const { change, rules, changed, cancel, working } = this.props;
    const { custom, redirect, create, update, label } = rules;
    const settings = System.system().entities[rules.api];

    return (
      <>
        <Box
          mode="padding"
          all={16}
          radius={8}
          shadow="small"
          color={Colors.white}
          width={512}
        >
          <Box
            display="flex"
            align="flex-start"
            justify="space-between"
            bottom={12}
          >
            <Rule rule="Title">{settings.label}</Rule>
            <Box direction="row" display="inline-flex">
              <Box left={12}>
                <Button
                  type="dark"
                  press={() => {
                    if (change) {
                      let list = [];

                      entities.map((e) => {
                        if (selected.includes(e.uuid)) {
                          list.push(e);
                        }
                      });

                      change(selected, list);
                    }
                  }}
                  loading={working}
                >
                  Okay
                </Button>
              </Box>
              <Box left={12}>
                <Button
                  type="white"
                  press={() => {
                    if (cancel) {
                      cancel();
                    }
                  }}
                  loading={working}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
          <Box bottom={12}>
            <Search
              value={search}
              change={(search) => {
                this.setState({ search, timer: true });
                this.setState({ timer: false });
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.search, 300);
                this.setState({ timer: true });
              }}
              loading={timer}
            />
          </Box>
          {entities.length > 0 ? (
            <Box
              radius={8}
              shadow="medium"
              mode="padding"
              left={16}
              top={8}
              bottom={8}
              right={16}
              style={{ maxHeight: 512, overflowY: "auto" }}
            >
              {entities.map((entity) => {
                if (search.length > 0) {
                  if (
                    !JSON.stringify(entity)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return undefined;
                  }
                }

                return (
                  <Box
                    key={entity.uuid}
                    mode="padding"
                    top={8}
                    bottom={8}
                    display="flex"
                    align="center"
                    direction="row"
                    press={() => {
                      let temporary = Lodash.clone(selected);
                      if (temporary.includes(entity.uuid)) {
                        Lodash.remove(temporary, function (e) {
                          return e === entity.uuid;
                        });
                        this.setState({ selected: temporary });
                      } else {
                        temporary.push(entity.uuid);
                        this.setState({ selected: temporary });
                      }
                    }}
                  >
                    {settings.columns.map((column) =>
                      column.type === "identifier" ? (
                        <Box
                          flex={1}
                          display="flex"
                          direction="column"
                          mode="padding"
                          right={16}
                        >
                          <Rule rule="Body" bottom={4}>
                            {label ? label(entity) : column.value(entity)}
                          </Rule>
                          <Rule rule="Small" opacity={0.5}>
                            {entity.uuid}
                          </Rule>
                        </Box>
                      ) : undefined
                    )}
                    <Check active={selected.includes(entity.uuid)} />
                  </Box>
                );
              })}
            </Box>
          ) : undefined}
        </Box>
      </>
    );
  }
}

export default Component;
