import Assistant from "./Assistant";

/**
 * c: column
 * - i: identifier
 */

const Minifier = {
  c: {
    i: (label, value, rules = false) =>
      Assistant.column(
        label,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid)
          : value,
        "identifier",
        rules
      ),
    s: (label, value, icon) =>
      Assistant.column(
        label,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid)
          : value,
        "string",
        false,
        typeof icon === "function"
          ? (entity) => icon(entity.parameters, entity.uuid)
          : icon
      ),
    l: (label, value, icon) =>
      Assistant.column(
        label,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid)
          : value,
        "long",
        false,
        typeof icon === "function"
          ? (entity) => icon(entity.parameters, entity.uuid)
          : icon
      ),
    r: (label, value, rules, icon) =>
      Assistant.column(
        label,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid)
          : value,
        "string",
        rules,
        typeof icon === "function"
          ? (entity) => icon(entity.parameters, entity.uuid)
          : icon
      ),
    b: (value, worker) =>
      Assistant.column(
        false,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid, entity, worker)
          : value,
        "button"
      ),
    g: (value) =>
      Assistant.column(
        false,
        typeof value === "function"
          ? (entity) => value(entity.parameters, entity.uuid)
          : value,
        "badge"
      ),
  },
};

export default Minifier;
