import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entity: false,
      entities: [],
      choose: false,
      search: "",
      timer: false,
    };
  }

  async componentDidMount() {
    const { value, rules } = this.props;
    const entities = await API.request("/@" + rules.api + "/read", {
      limit: 50,
    });
    if (entities.success) {
      this.setState({ entities: entities.data });
    }

    if (value) {
      if (value.length > 0) {
        let parameters = {};
        parameters[rules.api] = value;
        const find = await API.request("/@" + rules.api + "/read", parameters);
        if (find.success) {
          this.setState({ entity: find.data });
        }
      }
    }

    this.setState({ loading: false });
  }

  search = async () => {
    const { value, rules } = this.props;
    const { search } = this.state;

    let parameters = { limit: 50 };

    if (search.length > 0) {
      parameters.mode = "search";
      parameters.match = search;
    }

    const entities = await API.request("/@" + rules.api + "/read", parameters);
    if (entities.success) {
      this.setState({ entities: entities.data });
    }

    this.setState({ loading: false, timer: false });
    clearTimeout(this.timeout);
  };

  render() {
    const { entities, entity, loading, choose, search, timer } = this.state;
    const { change, rules, changed } = this.props;
    const { custom, redirect, create, update } = rules;
    const settings = System.system().entities[rules.api];

    return (
      <>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.10)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{settings.label}</Rule>
                <Box direction="row" display="inline-flex">
                  {settings.rules.create && create !== false ? (
                    <Box left={12}>
                      <Button
                        type="dark"
                        press={
                          !changed
                            ? () =>
                                Pages.screen(
                                  "@create/" + settings.api,
                                  false,
                                  settings.temporary
                                )
                            : () =>
                                Toast((toast) => (
                                  <Box display="flex" align="flex-start">
                                    <Box mode="padding" right={12} flex={1}>
                                      Kaydedilmemiş değişiklikler kaybolacaktır.
                                    </Box>
                                    <Button
                                      type="dark"
                                      press={() => {
                                        Toast.dismiss(toast.id);
                                        Pages.screen(
                                          "@create/" + settings.api,
                                          false,
                                          settings.temporary
                                        );
                                      }}
                                    >
                                      Tamam
                                    </Button>
                                  </Box>
                                ))
                        }
                      >
                        Create
                      </Button>
                    </Box>
                  ) : undefined}
                  <Box left={12}>
                    <Button
                      type="white"
                      press={() => this.setState({ choose: false })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => {
                    this.setState({ search, timer: true });
                    this.setState({ timer: false });
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(this.search, 300);
                    this.setState({ timer: true });
                  }}
                  loading={timer}
                />
              </Box>
              {entities.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {entities.map((entity) => {
                    if (search.length > 0) {
                      if (
                        !JSON.stringify(entity)
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return undefined;
                      }
                    }

                    return (
                      <Box
                        key={entity.uuid}
                        mode="padding"
                        top={8}
                        bottom={8}
                        display="flex"
                        align="center"
                        direction="row"
                      >
                        {settings.columns.map((column) =>
                          column.type === "identifier" ? (
                            <Box
                              flex={1}
                              display="flex"
                              direction="column"
                              mode="padding"
                              right={16}
                            >
                              <Rule rule="Body" bottom={4}>
                                {column.value(entity)}
                              </Rule>
                              <Rule rule="Small" opacity={0.5}>
                                {entity.uuid}
                              </Rule>
                            </Box>
                          ) : undefined
                        )}
                        <Button
                          type="disabled"
                          size="small"
                          press={() => {
                            this.setState({ entity, choose: false });
                            if (change) {
                              change(entity.uuid);
                            }
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction="row"
          align="center"
          justify="space-between"
        >
          {loading ? (
            <Box
              flex={1}
              align="center"
              justify="center"
              direction="row"
              display="flex"
            >
              <Box width={42} height={42}>
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            </Box>
          ) : custom && entity ? (
            custom(entity)
          ) : custom && !entity ? (
            <>
              <Rule rule="Body" display="block" bottom={4} opacity={0.5}>
                Not Selected
              </Rule>
              <Rule rule="Small" display="block" opacity={0.5}>
                {System.system().definitions[rules.api]}
              </Rule>
            </>
          ) : (
            <>
              <Box>
                {entity ? (
                  <>
                    <Rule
                      rule={rules.label ? "Body" : "Small"}
                      family={rules.label ? undefined : "Programme Regular"}
                      display="block"
                      bottom={4}
                    >
                      {rules.label ? rules.label(entity) : entity.uuid}
                    </Rule>
                    <Rule rule="Small" display="block" opacity={0.5}>
                      {System.system().definitions[rules.api]}
                    </Rule>
                  </>
                ) : (
                  <>
                    <Rule rule="Body" display="block" bottom={4} opacity={0.5}>
                      Not Selected
                    </Rule>
                    <Rule rule="Small" display="block" opacity={0.5}>
                      {System.system().definitions[rules.api]}
                    </Rule>
                  </>
                )}
              </Box>
              <Box display="flex" align="center" direction="row">
                {entity && update !== false ? (
                  <Button
                    size="small"
                    type="disabled"
                    press={() => {
                      this.setState({ entity: false });
                      if (change) {
                        change("");
                      }
                    }}
                  >
                    Clear
                  </Button>
                ) : undefined}
                {entity && redirect === true ? (
                  <Box left={4}>
                    <Button
                      size="small"
                      type="disabled"
                      press={() =>
                        Pages.screen("@entity/" + settings.api, entity.uuid)
                      }
                    >
                      Display
                    </Button>
                  </Box>
                ) : undefined}
                {update !== false ? (
                  <Box left={4}>
                    <Button
                      size="small"
                      press={() => this.setState({ choose: true })}
                      type="dark"
                    >
                      {entity ? "Replace" : "Choose"}
                    </Button>
                  </Box>
                ) : undefined}
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Component;
