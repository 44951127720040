import React from "react";
import {
  Store,
  Navigator,
  Auth,
  Phone,
  Rates,
  Popups,
  Children,
  Quick,
  Basket,
  Print,
} from "./Modules";
import { Proforma, BoardIndividualDocument } from "./Project";
import { Resources } from "./Helpers";
import { System } from "./System";
import { Mobile, Sequence } from "./Bitter";
import {
  PopupsContainer,
  SheetsContainer,
  ChildrenContainer,
  QuickContainer,
  BasketContainer,
  PrintContainer,
} from "./Containers";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    Auth.auth();
    Phone.bootstrap();
  }

  render() {
    return (
      <Provider store={Store}>
        <Helmet>
          <title>{Resources.app} Beta</title>
        </Helmet>
        <Navigator />
        <Toaster />
        <PopupsContainer />
        <SheetsContainer />
        <ChildrenContainer />
        <QuickContainer />
        <BasketContainer />
        <PrintContainer />
        <Sequence />
      </Provider>
    );
  }
}

export default App;
