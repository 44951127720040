import Store from "./Store";
import Lodash from "lodash";

class Remember {
  static remember(data) {
    Store.dispatch({ type: "CORE", key: "remember", value: data });
  }

  static think() {
    return Lodash.clone(Store.getState().remember);
  }

  static forget() {
    Store.dispatch({ type: "CORE", key: "remember", value: false });
  }
}

export default Remember;
