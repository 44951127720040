import Lodash from "lodash";

const CALLBACKS = {
  string: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  editor: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  attachment: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  password: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  number: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = isNaN(value)
      ? parseFloat(value, 10) || 0 > parseInt(value, 10) || 0
        ? parseFloat(value, 10) || 0
        : parseInt(value, 10) || 0
      : value;
    return clone;
  },
  select: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  text: (entity) => entity,
  date: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  time: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  schedule: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  entity: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d1: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d2: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d3: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d4: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
};

const BLOCKS = {
  string: (key, label, rules = false, change = false) => {
    return {
      type: "string",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  editor: (key, label, rules = false, change = false) => {
    return {
      type: "editor",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  attachment: (key, label, rules = false, change = false, value = false) => {
    return {
      type: "attachment",
      key,
      label,
      value: value
        ? (entity) => value(entity)
        : (entity) => entity.parameters[key],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  password: (key, label, rules = false, change = false) => {
    return {
      type: "password",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  number: (key, label, rules = false, change = false) => {
    return {
      type: "number",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  select: (key, label, options = [], rules = false, change = false) => {
    return {
      type: "select",
      key,
      label,
      value: (entity) => entity.parameters[key],
      options: [...options],
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  text: (key, label, rules = false, value = false) => {
    return {
      type: "text",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: value ? value : (entity) => entity.parameters[key],
    };
  },
  date: (key, label, rules = false, change = false) => {
    return {
      type: "date",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  entity: (key, label, rules = false, change = false) => {
    return {
      type: "entity",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  d1: (key, label, rules = false, change = false) => {
    return {
      type: "d1",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d1(entity, key, value),
    };
  },
  d2: (key, label, rules = false, change = false) => {
    return {
      type: "d2",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d2(entity, key, value),
    };
  },
  d3: (key, label, rules = false, change = false) => {
    return {
      type: "d3",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d3(entity, key, value),
    };
  },
  d4: (key, label, rules = false, change = false) => {
    return {
      type: "d4",
      key,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d4(entity, key, value),
    };
  },
  entities: (label, rules) => {
    return {
      type: "entities",
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
    };
  },
  connected: (label, rules) => {
    return {
      type: "connected",
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
    };
  },
  custom: (jsx, label, rules) => {
    return {
      type: "custom",
      jsx,
      label,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
    };
  },
};

const MENUS = {
  bitter: (label, value, icon = false, options) => {
    return { label, value, icon, ...options };
  },
};

const RULES = {
  rules: (rules) => {
    let temporary = {
      create: false,
      read: false,
      update: false,
      delete: false,
      custom: [],
    };

    rules.map((rule) => {
      if (typeof rule === "string") {
        temporary[rule] = true;
      } else {
        temporary[rule.key] = rule.value;
      }
    });

    return temporary;
  },
};

const Assistant = {
  block: BLOCKS,
  menu: MENUS,
  tab: (label, blocks, display = true, options = {}) => {
    return {
      label,
      display:
        typeof display === "function" ? (entity) => display(entity) : display,
      blocks,
      options,
    };
  },
  column: (
    label,
    value,
    type = "string",
    rules = false,
    icon = false,
    press = false
  ) => {
    return {
      label,
      value: value ? (entity) => value(entity) : false,
      rules: (entity, mode) => (rules ? rules(entity, mode) : false),
      type,
      icon: icon ? (entity) => icon(entity) : false,
      press: press ? (entity) => press(entity) : undefined,
    };
  },
  entities: (
    api,
    label,
    rules = [],
    columns = [],
    temporary = false,
    override = false,
    mode = "none",
    filter = false,
    options = {}
  ) => {
    return {
      api,
      label,
      columns,
      rules: RULES.rules(rules),
      temporary,
      override,
      mode,
      filter,
      options,
    };
  },
  entity: (
    api,
    label,
    rules = [],
    values = [],
    temporary = false,
    mode = "none"
  ) => {
    return {
      api,
      label,
      rules: RULES.rules(rules),
      blocks: mode === "none" ? values : false,
      tabs: mode === "tabs" ? values : false,
      temporary,
    };
  },
};

export default Assistant;
