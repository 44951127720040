import { createStore } from "redux";

const STATE = {
  drift: false,
  rates: false,
  remember: false,
  admin: "ADMIN",
  screen: "@home",
  entity: false,
  temporary: false,
  override: false,
  professional: false,
  history: [
    {
      screen: "",
      entity: false,
      temporary: false,
      override: false,
    },
  ],
  user: false,
  sidebar: false,
  native: false,
  sequence: false,
  popups: [],
  sheets: [],
  childrens: [],
  quicks: [],
  baskets: [],
  expand: true,
  print: false,
  dark: false
};

const reducer = (state = STATE, action) => {
  var holder = { ...state };
  switch (action.type) {
    case "CORE":
      holder[action.key] = action.value;
      return holder;
    default:
      return holder;
  }
};

const store = createStore(reducer);

export default store;
