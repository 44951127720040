import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Mobile,
  Rule,
  Line,
  Button,
  Input,
  Icon,
  Basket,
} from "../Bitter";
import { Colors } from "../Helpers";

import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const { print } = this.props.redux;

    return (
      <>
        <Mobile
          render={(mobile) =>
            print ? (
              <Box
                display="block"
                mode="position"
                position="fixed"
                width="100%"
                height="100%"
                left={print.display ? undefined : -99999}
                bottom={print.display ? undefined : -99999}
                color="rgba(10,15,13,0.30)"
                display="flex"
                align="center"
                justify="flex-end"
                direction="column"
                style={{ boxSizing: "border-box", padding: 24 }}
                layer={7}
              >
                <Box
                  width="100%"
                  height="100%"
                  direction="column"
                  flex={1}
                  display="flex"
                  style={{ maxWidth: 720 }}
                  color={Colors.white}
                  radius={8}
                  overflow="auto"
                >
                  <div id="print">{print.render()}</div>
                </Box>
              </Box>
            ) : undefined
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
