import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    const menus = [];

    const levels = {
      admin: [Assistant.menu.bitter("Users", "users", "smile")],
      permission: [],
    };

    levels.admin.map((level) => {
      if (Permissions.permission("*")) {
        menus.push(level);
      }
    });

    levels.permission.map((level) => {
      if (Permissions.permission("menu:" + level.value)) {
        menus.push(level);
      }
    });

    return menus;
  },
};

export default Bite;
