import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entities(
      "collaborations",
      "Collaborations",
      ["create"],
      [
        M.c.i("Date", ({ date }) => date),
        M.c.s("Name", ({ name }) => name),
        M.c.s("Phone", ({ phone }) => phone),
      ]
    );
  },
};

export default Bite;
