import React from "react";

import { Colors, Functions, Resources } from "../../Helpers";
import { Box, Rule, Button, Icon, Sheets as SheetsActions } from "../../Bitter";
import { API, Pages, Sheets, Children } from "../../Modules";
import { System, Permissions } from "../../System";
import { Empty } from "../../Project";
import BarLoader from "react-spinners/BarLoader";
import Toast from "react-hot-toast";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Lodash from "lodash";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      entity: false,
      loading: true,
      preview: false,
      pages: 1,
      page: 1,
      visible: false,
    };

    this.input = null;
  }

  async componentDidMount() {
    const { rules, value } = this.props;
    const { mode } = rules;
    const files = [];

    if (mode === "single") {
      files.push(value);
    }

    if (mode !== "single" && typeof value === "object") {
      value.map((v) => {
        files.push(v);
      });
    }

    if (files.length > 0) {
      const attachments = await API.request("/uploads/attachments", {
        attachments: files,
      });
      if (attachments.success) {
        this.setState({ attachments: attachments.data, loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  upload = async (base64, name = "") => {
    const { rules, change } = this.props;
    const { mode } = rules;

    const { attachments } = this.state;
    const upload = await API.request("/uploads/upload", {
      data: base64,
      name,
    });
    if (upload.success) {
      Toast.success(upload.message);
      const temporary = Lodash.clone(attachments);
      temporary.push(upload.data);
      this.setState({ attachments: temporary });

      if (mode === "single") {
        if (change) {
          change(temporary[0].uuid);
        }
      } else {
        const files = [];
        temporary.map((file) => {
          files.push(file.uuid);
        });
        if (change) {
          change(files);
        }
      }
    }
  };

  file = () => {
    if (this.input) {
      if (this.input.files.length > 0) {
        const file = this.input.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.upload(reader.result, this.input.files[0].name);
        };
      }
    }
  };

  delete = (uuid) => {
    const { rules, change } = this.props;
    const { mode } = rules;
    const temporary = [];
    const { attachments } = this.state;
    attachments.map((attachment) => {
      if (attachment.uuid !== uuid) {
        temporary.push(attachment);
      }
    });
    this.setState({ attachments: temporary });

    if (mode === "single") {
      if (change) {
        change(temporary[0].uuid);
      }
    } else {
      const files = [];
      temporary.map((file) => {
        files.push(file.uuid);
      });
      if (change) {
        change(files);
      }
    }
  };

  trigger = async (mode) => {
    const { attachments } = this.state;
    this.setState({ visible: false });
    if (mode === "upload") {
      if (this.input) {
        this.input.click();
      }
    }
    if (mode === "choose") {
      let children = await Children.quick(
        "attachments",
        {},
        {
          label: (temporary) =>
            temporary.parameters.name +
            " > " +
            temporary.parameters.extension.toUpperCase() +
            " > " +
            Functions.bytes(temporary.parameters.size),
        }
      );
      try {
        if (children) {
          if (children.entities.length > 0) {
            const temporary = Lodash.clone(attachments);
            children.entities.map((c) => {
              c.parameters.uuid = c.uuid;
              temporary.push(c.parameters);
            });
            this.setState({ attachments: temporary });
          }
        }
      } catch (Error) {
        console.error(Error);
      }
    }
  };

  render() {
    const {
      entity,
      loading,
      preview,
      attachments,
      pages,
      page,
      visible,
    } = this.state;
    const { change, rules, changed } = this.props;

    const { maximum, types = [], editable = true } = rules;

    let accept = "";
    types.map((type) => (accept += type + ","));
    accept = accept.replace(/,\s*$/, "");

    let display = { extension: "", uuid: "", size: 0, type: "" };
    attachments.map((attachment) => {
      if (attachment.uuid === preview) {
        display = attachment;
      }
    });

    return (
      <>
        {preview ? (
          <Box
            position="fixed"
            mode="position"
            left={0}
            top={0}
            width="100%"
            height="100%"
            color="rgba(0,0,0,0.9)"
            layer={999}
          >
            <Box
              position="absolute"
              mode="position"
              left={24}
              right={24}
              bottom={80}
              top={72}
              layer={3}
              display="flex"
              align="center"
              justify="center"
              style={{
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: ["png", "jpg", "jpeg"].includes(
                  display.extension
                )
                  ? "url(" +
                    Resources.url +
                    "/uploads/preview/" +
                    display.uuid +
                    "." +
                    display.extension +
                    ")"
                  : undefined,
              }}
            >
              {["pdf"].includes(display.extension) ? (
                <>
                  <Document
                    file={{
                      url:
                        Resources.url +
                        "/uploads/preview/" +
                        display.uuid +
                        "." +
                        display.extension,
                    }}
                    onLoadSuccess={(pages) =>
                      this.setState({ pages: pages.numPages })
                    }
                  >
                    <Page pageNumber={page} height={window.innerHeight - 152} />
                  </Document>
                </>
              ) : undefined}
              {["png", "jpg", "jpeg", "pdf"].includes(
                display.extension
              ) ? undefined : (
                <Empty
                  color={Colors.white}
                  title="Oops"
                  description="No preview is available for this attachment. You can try to download the attachment."
                />
              )}
            </Box>
            <Box
              position="absolute"
              mode="position"
              left="unset"
              top="unset"
              right={24}
              bottom={24}
              layer={3}
              display="inline-flex"
              direction="row"
            >
              {["pdf"].includes(display.extension) && pages > 1 ? (
                <Box
                  color={Colors.white}
                  display="inline-flex"
                  align="center"
                  radius={2}
                >
                  <Button
                    width={32}
                    type={page <= 1 ? "disabled" : "white"}
                    icon="arrow-left"
                    feather={true}
                    press={
                      page <= 1
                        ? undefined
                        : () => this.setState({ page: page - 1 })
                    }
                  />
                  <Box
                    mode="padding"
                    left={8}
                    right={8}
                    height={32}
                    display="inline-flex"
                    align="center"
                    justify="center"
                    style={{ minWidth: 32 }}
                  >
                    <Rule rule="Thin">{page}</Rule>
                  </Box>
                  <Button
                    width={32}
                    icon="arrow-right"
                    feather={true}
                    type={page >= pages ? "disabled" : "white"}
                    press={
                      page >= pages
                        ? undefined
                        : () => this.setState({ page: page + 1 })
                    }
                  />
                </Box>
              ) : undefined}
              <Button
                type="white"
                left={12}
                press={() =>
                  Functions.install(
                    Resources.url +
                      "/uploads/preview/" +
                      display.uuid +
                      "." +
                      display.extension,
                    display.uuid + "." + display.extension
                  )
                }
              >
                Download
              </Button>
            </Box>
            <Box
              position="absolute"
              mode="position"
              left="50%"
              top="unset"
              right="unset"
              bottom={24}
              display="inline-flex"
              align="center"
              justify="center"
              layer={3}
              style={{ transform: "translateX(-50%)" }}
            >
              {loading
                ? undefined
                : attachments.map((attachment) => (
                    <Box
                      mode="margin"
                      right={2}
                      left={2}
                      radius={4}
                      direction="column"
                      display="inline-flex"
                      align="center"
                      style={{
                        border:
                          preview === attachment.uuid
                            ? "2px solid white"
                            : " 2px solid transparent",
                      }}
                    >
                      <Box
                        press={() =>
                          this.setState({ preview: attachment.uuid })
                        }
                        position="relative"
                        height={32}
                        width={32}
                        radius={2}
                        display="inline-flex"
                        align="center"
                        justify="center"
                        direction="column"
                        color="#F7F7F7"
                        style={{
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: ["png", "jpg", "jpeg"].includes(
                            attachment.extension
                          )
                            ? "url(" +
                              Resources.url +
                              "/uploads/preview/" +
                              attachment.uuid +
                              "." +
                              attachment.extension +
                              ")"
                            : undefined,
                        }}
                      >
                        {["png", "jpg", "jpeg"].includes(
                          attachment.extension
                        ) ? undefined : (
                          <>
                            <Icon
                              bottom={4}
                              size={12}
                              color={Colors.black}
                              opacity={0.5}
                              icon="paperclip"
                              feather={true}
                            />
                            <Rule rule="Micro" opacity={0.5} size={8}>
                              {attachment.extension.toUpperCase()}
                            </Rule>
                          </>
                        )}
                      </Box>
                    </Box>
                  ))}
            </Box>
            <Box
              position="absolute"
              mode="position"
              left={24}
              top={24}
              right="unset"
              bottom="unset"
              layer={3}
            >
              <Icon
                size={24}
                icon="x"
                feather={true}
                color={Colors.white}
                press={() => this.setState({ preview: false })}
              />
            </Box>
          </Box>
        ) : undefined}
        <input
          key="attachment"
          type="file"
          accept={accept}
          hidden={true}
          style={{ display: "none", visible: "none" }}
          ref={(ref) => {
            this.input = ref;
            console.log({ ref });
          }}
          onChange={this.file}
        />
        <Box
          style={{ overflowX: "auto" }}
          display="flex"
          align="flex-start"
          direction="row"
          wrap="flex-wrap"
        >
          {loading ? (
            <Box
              mode="margin"
              right={4}
              bottom={4}
              direction="column"
              display="inline-flex"
              align="center"
            >
              <Box
                position="relative"
                color="#F7F7F7"
                height={64}
                width={64}
                radius={2}
                mode="margin"
                bottom={4}
                align="center"
                justify="center"
                display="inline-flex"
              >
                <BarLoader
                  height={6}
                  width={32}
                  color={Colors.black}
                  loading={true}
                />
              </Box>
            </Box>
          ) : undefined}
          {loading
            ? undefined
            : attachments.map((attachment) => (
                <Box
                  mode="margin"
                  right={4}
                  bottom={4}
                  direction="column"
                  display="inline-flex"
                  align="center"
                >
                  <Box
                    position="relative"
                    height={64}
                    width={64}
                    radius={2}
                    bottom={4}
                    display="inline-flex"
                    align="center"
                    justify="center"
                    direction="column"
                    color="#F7F7F7"
                    style={{
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: ["png", "jpg", "jpeg"].includes(
                        attachment.extension
                      )
                        ? "url(" +
                          Resources.url +
                          "/uploads/preview/" +
                          attachment.uuid +
                          "." +
                          attachment.extension +
                          ")"
                        : undefined,
                    }}
                    press={() => this.setState({ preview: attachment.uuid })}
                  >
                    {["png", "jpg", "jpeg"].includes(
                      attachment.extension
                    ) ? undefined : (
                      <Icon
                        size={24}
                        color={Colors.black}
                        opacity={0.5}
                        icon="paperclip"
                        feather={true}
                      />
                    )}
                    {editable ? (
                      <Box
                        position="absolute"
                        mode="position"
                        top="unset"
                        left="unset"
                        right={4}
                        bottom={4}
                        height={12}
                        width={12}
                        color={Colors.white}
                        radius={2}
                        display="inline-flex"
                        align="center"
                        justify="center"
                        shadow="medium"
                      >
                        <Icon
                          icon="x"
                          color={Colors.black}
                          feather={true}
                          size={14}
                          press={(event) => {
                            try {
                              event.stopPropagation()();
                              event.preventDefault();
                            } catch (Error) {
                              console.error(Error);
                            }

                            this.delete(attachment.uuid, (preview: false));
                          }}
                        />
                      </Box>
                    ) : undefined}
                  </Box>
                  <Rule rule="Micro" opacity={0.5}>
                    {attachment.extension.toUpperCase()} -{" "}
                    {Functions.bytes(attachment.size)}
                  </Rule>
                </Box>
              ))}
          {loading ? undefined : (attachments.length >= maximum && maximum) ||
            !editable ? undefined : (
            <Box
              mode="margin"
              right={4}
              bottom={4}
              direction="column"
              display="inline-flex"
              align="center"
            >
              <Box
                position="relative"
                height={64}
                width={64}
                radius={2}
                display="inline-flex"
                align="center"
                justify="center"
                direction="column"
                color={Colors.primary}
                press={() => this.setState({ visible: true })}
              >
                <Icon
                  size={16}
                  color={Colors.white}
                  icon="paperclip"
                  bottom={4}
                />
                <Rule rule="Tiny" color={Colors.white}>
                  Attach
                </Rule>
              </Box>
            </Box>
          )}
        </Box>
        <SheetsActions
          visible={visible}
          press={(mode) => this.trigger(mode)}
          actions={
            Permissions.permission("*")
              ? [
                  { icon: "upload", text: "Upload", value: "upload" },
                  { icon: "copy", text: "Choose", value: "choose" },
                ]
              : [{ icon: "upload", text: "Upload", value: "upload" }]
          }
        />
      </>
    );
  }
}

export default Component;
