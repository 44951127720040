import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, QuickEntity } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entities: [],
      quick: false,
    };
  }

  async componentDidMount() {
    const { rules } = this.props;

    let parameters = {};

    if (rules.filter) {
      parameters = {
        ...rules.filter(),
      };
    }
    const entities = await API.request("/@" + rules.api + "/read", parameters);
    if (entities.success) {
      this.setState({ entities: entities.data, loading: false });
    }
  }

  render() {
    const { loading, quick, entities } = this.state;
    const { rules, changed, worker } = this.props;
    const settings = System.system().entities[rules.api];

    return (
      <>
        {quick ? (
          <QuickEntity
            close={(reload = false) => {
              this.setState({ quick: false });
              if (worker && reload) {
                try {
                  worker.reload();
                } catch (Error) {
                  console.error(Error);
                }
              }
            }}
            buttons={rules.buttons || []}
            settings={quick.settings}
            entity={quick.entity}
          />
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction={loading ? "row" : "column"}
          align={loading ? "center" : undefined}
          justify={loading ? "space-between" : undefined}
        >
          {loading ? (
            <Box
              flex={1}
              align="center"
              justify="center"
              direction="row"
              display="flex"
            >
              <Box width={42} height={42}>
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            </Box>
          ) : (
            <>
              {entities.length > 0 ? (
                entities.map((entity) => (
                  <Box
                    key={entity.uuid}
                    mode="padding"
                    top={8}
                    bottom={8}
                    display="flex"
                    flex={1}
                    align="center"
                    direction="row"
                  >
                    {settings.columns.map((column) =>
                      column.type === "identifier" ? (
                        <Box
                          flex={1}
                          display="flex"
                          direction="column"
                          mode="padding"
                          right={16}
                        >
                          <Rule rule="Body" bottom={4}>
                            {rules.label
                              ? rules.label(entity)
                              : column.value(entity)}
                          </Rule>
                          <Rule rule="Small" opacity={0.5}>
                            {entity.uuid}
                          </Rule>
                        </Box>
                      ) : undefined
                    )}
                    <Button
                      type={rules.quick ? "download" : "disabled"}
                      icon={rules.quick ? "eye" : undefined}
                      size="small"
                      press={
                        rules.quick === true
                          ? () =>
                              this.setState({
                                quick: {
                                  entity: entity.uuid,
                                  settings: System.system().entity[
                                    settings.api
                                  ],
                                },
                              })
                          : !changed
                          ? () =>
                              Pages.screen(
                                "@entity/" + settings.api,
                                entity.uuid
                              )
                          : () =>
                              Toast((toast) => (
                                <Box display="flex" align="flex-start">
                                  <Box mode="padding" right={12} flex={1}>
                                    Kaydedilmemiş değişiklikler kaybolacaktır.
                                  </Box>
                                  <Button
                                    type="dark"
                                    press={() => {
                                      Toast.dismiss(toast.id);
                                      Pages.screen(
                                        "@entity/" + settings.api,
                                        entity.uuid
                                      );
                                    }}
                                  >
                                    Tamam
                                  </Button>
                                </Box>
                              ))
                      }
                    >
                      {rules.quick === true ? "Display" : "Details"}
                    </Button>
                  </Box>
                ))
              ) : (
                <>
                  <Box
                    mode="margin"
                    radius={8}
                    bottom={8}
                    top={8}
                    height={38}
                    border="1px dashed #DEDEDE"
                  ></Box>
                  <Box
                    mode="margin"
                    radius={8}
                    bottom={8}
                    top={8}
                    height={38}
                    border="1px dashed #DEDEDE"
                  ></Box>
                </>
              )}
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Component;
