import React from "react";
import { HashRouter, BrowserRouter, Switch, Route } from "react-router-dom";

import { DashboardContainer, DocumentationContainer } from "../Containers";

import Electron from "is-electron";

class Component extends React.PureComponent {
  render() {
    return Electron() ? (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={DashboardContainer} />
        </Switch>
      </HashRouter>
    ) : (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={DashboardContainer} />
          <Route exact path="/documentation" component={DocumentationContainer} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Component;
