import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  D1,
  Input,
  Selector,
  Badge,
} from "../Bitter";
import { Functions, Colors, Resources, Images } from "../Helpers";
import { Pages, API, Auth } from "../Modules";
import { Proforma } from "../Project";
import Lodash from "lodash";
import Toast from "react-hot-toast";

const Tabs = ({
  label = () => {
    return "";
  },
  render = () => {},
  data = [],
}) => {
  const [current, setCurrent] = useState(0);
  return (
    <Box>
      <Box display="flex" bottom={24}>
        {data.map((d, dd) => (
          <Box
            press={() => setCurrent(dd)}
            mode="padding"
            all={6}
            radius={3}
            color={dd === current ? "#DDD" : "#F7F7F7"}
            style={{ marginRight: 4 }}
          >
            <Rule rule="Small">{label(d)}</Rule>
          </Box>
        ))}
      </Box>
      {data.map((d, dd) => {
        if (dd === current) {
          return render(d);
        }

        return null;
      })}
    </Box>
  );
};

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      documentation: [],
    };
  }

  async componentDidMount() {
    const documentation = await API.request("/documentation");
    if (documentation.success) {
      this.setState({ documentation: documentation.data });
    }
  }

  render() {
    const { documentation } = this.state;

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <Box
          width="100%"
          style={{ maxWidth: 700 }}
          mode="margin"
          left="auto"
          right="auto"
          height="100%"
          overflow="auto"
        >
          <Box height={48} />
          {documentation.map((doc) => (
            <Box bottom={48}>
              <Box
                mode="padding"
                all={24}
                radius={6}
                color="white"
                shadow="medium"
              >
                <Rule display="block" bottom={12} rule="Subtitle">
                  {doc.title}
                </Rule>
                <Tabs
                  data={doc.endpoints}
                  label={(data) => data.boilerplate}
                  render={(data) => {
                    return (
                      <Box>
                        <Box bottom={12} display="flex">
                          <Box right={12}>
                            <Badge>{data.method}</Badge>
                          </Box>

                          <Badge background="#F7F7F7" color="#101115">
                            {data.endpoint}
                          </Badge>
                        </Box>
                        <Box bottom={6}>
                          <Rule rule="Body">Parameters</Rule>
                        </Box>
                        <Box>
                          {data.parameters.map((p) => (
                            <Box display="flex" top={6}>
                              <Box flex={1}>
                                <Rule rule="Small">{p.name}</Rule>
                              </Box>
                              <Box flex={1}>
                                <Rule rule="Small" opacity={0.75}>
                                  {p.type}
                                </Rule>
                              </Box>
                              <Box flex={1}>
                                <Rule rule="Small" opacity={0.5}>
                                  {p.default}
                                </Rule>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    );
                  }}
                />
              </Box>
            </Box>
          ))}
          <Box height={48} />
        </Box>
        <Box
          display="flex"
          align="center"
          justify="center"
          mode="padding"
          all={24}
        >
          <Rule right={12}>Powered By</Rule>
          <img
            src={Images.src("assets/i/power.svg")}
            width="auto"
            display="block"
            height="24"
            width="24"
            alt={Resources.app}
          />
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
