import React, { useState } from "react";
import { connect } from "react-redux";

import { Box, Rule, Icon } from "../../Bitter";
import { Colors } from "../../Helpers";
import { CheckmarkIcon, ErrorIcon, LoaderIcon } from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }
  render() {
    const { open } = this.state;
    const { sequence, drift } = this.props.redux;
    return sequence ? (
      <Box
        mode="position"
        width={400}
        height={open ? 300 : drift ? 92 : "auto"}
        position="fixed"
        layer={9}
        bottom={18}
        right={18}
        left="unset"
        top="unset"
        color={Colors.white}
        radius={4}
        shadow="large"
      >
        <Box
          left={18}
          right={drift ? 94 : 18}
          top={18}
          bottom={18}
          height="100%"
          mode="padding"
          width="100%"
        >
          <Box height="100%" overflow="auto">
            <Box
              mode="padding"
              bottom={open ? 24 : 0}
              display="flex"
              align="center"
              opacity={1}
              direction="row"
              justify="space-between"
            >
              <Box
                display="flex"
                align="center"
                opacity={1}
                direction="row"
                mode="padding"
                right={12}
                overflow="hidden"
              >
                {!open ? (
                  <>
                    <Box right={12} width={20} height={20}>
                      {sequence.rows[sequence.rows.length - 1].type ===
                      "success" ? (
                        <CheckmarkIcon />
                      ) : sequence.rows[sequence.rows.length - 1].type ===
                        "error" ? (
                        <ErrorIcon />
                      ) : sequence.rows[sequence.rows.length - 1].type ===
                        "loading" ? (
                        <LoaderIcon />
                      ) : undefined}
                    </Box>
                    {sequence.rows[sequence.rows.length - 1].text}
                  </>
                ) : (
                  <>
                    <Box right={12} width={20} height={20}>
                      <img
                        width={20}
                        height={20}
                        alt="https://img.icons8.com/color-glass/48/lightning-bolt.png"
                        src="https://img.icons8.com/color-glass/48/lightning-bolt.png"
                      />
                    </Box>
                    Sequence
                  </>
                )}
              </Box>
              {drift ? undefined : (
                <Icon
                  press={() => this.setState({ open: !open })}
                  opacity={0.25}
                  icon={open ? "chevron-down" : "chevron-up"}
                />
              )}
            </Box>
            {open && sequence.rows
              ? sequence.rows.map((row) => (
                  <Box
                    bottom={12}
                    display="flex"
                    align="flex-start"
                    direction="row"
                  >
                    <Box
                      width={20}
                      height={20}
                      style={{ minWidth: 20 }}
                      display="inline-flex"
                      align="center"
                      justify="center"
                    >
                      {row.type === "success" ? (
                        <CheckmarkIcon />
                      ) : row.type === "error" ? (
                        <ErrorIcon />
                      ) : row.type === "loading" ? (
                        <LoaderIcon />
                      ) : undefined}
                    </Box>
                    <Box flex={1} left={12} mode="padding">
                      <Rule
                        opacity={
                          ["finish", "start"].includes(row.type) ? 0.5 : 1
                        }
                        rule="Small"
                      >
                        {row.text}
                      </Rule>
                    </Box>
                  </Box>
                ))
              : undefined}
          </Box>
        </Box>
      </Box>
    ) : null;
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
