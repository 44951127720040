import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entity(
      "permissions",
      (entity) => entity.parameters.label,
      ["update", "delete"],
      [
        Assistant.block.string("label", "Label"),
        Assistant.block.d2("permissions", "Permissions"),
      ]
    );
  },
};

export default Bite;
