import React, { useState } from "react";

import { Colors } from "../../Helpers";
import {
  Box,
  Rule,
  Icon,
  D5,
  Mobile,
  D6,
  Search,
  Professional,
} from "../../Bitter";

import Lodash from "lodash";

const Component = ({ value, change, rules, header = false }) => {
  let data = [];

  if (typeof value === "object") {
    data = value;
  }

  let [search, setSearch] = useState("");

  let find = search.length > 0 ? search : false;

  let [datas, set] = useState(data);

  const {
    labels = {},
    move = true,
    trash = true,
    object,
    duplicate = false,
    hidden = [],
    locked = [],
    numeric = [],
    options = {},
    hydrate = false,
    defaults,
  } = rules;

  const [opens, setOpens] = useState({});

  if (hydrate) {
    datas.map((_, __) => {
      Object.entries(_).map(([___, ____]) => {
        if (hydrate === ___) {
          if (____.length > 0) {
            if (!options.value) {
              options.value = [];
            }
            options.value.push({
              key: "code",
              value: _.code,
              options: ____,
            });
          }
        }
      });
    });
  }

  const OPTIONS = (item, entries, labels) => {
    if (options[item]) {
      let main = false;
      let name = undefined;

      if (options[item].force) {
        return options[item].options;
      }

      if (Array.isArray(options[item])) {
        options[item].map((o) => {
          if (o.key && o.value) {
            let found = false;

            Object.entries(entries).map(([e, ee]) => {
              if (e === o.key && ee === o.value) {
                found = true;
              }
            });

            if (found) {
              name = entries.label || undefined;
              main = o.options;
            } else {
              return false;
            }
          } else {
            name = entries.label || undefined;
            main = o.options;
          }
        });
      } else {
        if (options[item].key && options[item].value) {
          let found = false;

          Object.entries(entries).map(([e, ee]) => {
            if (e === options[item].key && ee === options[item].value) {
              found = true;
            }
          });

          if (found) {
            name = entries.label || undefined;
            return options[item].options;
          } else {
            return false;
          }
        } else {
          name = entries.label || undefined;
          return options[item].options;
        }
      }

      if (main) {
        main.map((m, mm) => {
          main[mm].name = name;
        });
        return main;
      }
    }
    return false;
  };

  let lister = find ? [] : datas;

  let indexes = {};

  if (!find) {
    datas.map((_, __) => {
      indexes[__] = __;
    });
  }

  if (find) {
    let temporary = Lodash.clone(datas);
    let i = 0;
    temporary = Lodash.filter(temporary, (d, dd) => {
      if (!JSON.stringify(d).toLowerCase().includes(find.toLowerCase())) {
        return false;
      }

      indexes[i] = dd;
      i++;
      return true;
    });
    lister = temporary;
  }

  return (
    <Professional
      render={(professional) => (
        <Mobile
          render={(mobile) => (
            <Box bottom={header ? 24 : 0}>
              {rules.header || header ? (
                <Box
                  direction="row"
                  display="flex"
                  align="center"
                  justify="space-between"
                  bottom={12}
                >
                  <Rule rule="Body" display="block">
                    {rules.header || header}
                  </Rule>
                  {rules.create === false ? undefined : (
                    <Icon
                      icon="plus"
                      press={() => {
                        let clone = Lodash.clone(data);
                        let temporary = {};
                        Lodash.each(rules.labels, (__, ___) => {
                          temporary[___] = defaults ? defaults[___] || "" : "";
                        });
                        clone.push(temporary);

                        set(clone);

                        if (change) {
                          change(clone);
                        }
                      }}
                    />
                  )}
                </Box>
              ) : undefined}
              <Box>
                {rules.search !== false && datas.length > 0 ? (
                  <Box bottom={12}>
                    <Search
                      value={search}
                      change={(search) => setSearch(search)}
                    />
                  </Box>
                ) : undefined}
                {lister.map((_, __) => (
                  <Box
                    display="flex"
                    mode="margin"
                    bottom={
                      (__ + 1 < datas.length ? (professional ? 2 : 8) : 0) -
                      (mobile ? (professional ? -1 : -4) : 0)
                    }
                    key={indexes[__]}
                  >
                    <Box
                      mode="margin"
                      left={professional ? -1 : -4}
                      right={professional ? 1 : 4}
                      display="flex"
                      flex={1}
                      direction={mobile ? "column" : "row"}
                    >
                      {Object.entries(_).map(([___, ____]) => {
                        if (hidden.includes(___)) {
                          return null;
                        }
                        return (
                          <Box
                            left={professional ? 1 : 4}
                            right={professional ? 1 : 4}
                            mode="margin"
                            bottom={0}
                            flex={1}
                            bottom={mobile ? (professional ? 1 : 4) : 0}
                            key={indexes[__] + "-" + ___}
                          >
                            <Box display="flex">
                              <Box
                                color="#F7F7F7"
                                mode="padding"
                                all={professional ? 4 : 12}
                                radius={professional ? 4 : 8}
                                flex={1}
                                height={professional ? 48 : 64}
                                display="flex"
                                direction="column"
                                justify={
                                  professional ? "space-between" : "center"
                                }
                              >
                                {labels[___] ? (
                                  <Rule
                                    bottom={4}
                                    display="block"
                                    rule={professional ? "Tiny" : "Small"}
                                    opacity={0.5}
                                    style={{
                                      height: professional ? 12 : 14,
                                      overflow: "hidden",
                                    }}
                                  >
                                    {labels[___]}
                                  </Rule>
                                ) : (
                                  ___
                                )}
                                {typeof ____ === "object" ? (
                                  <>
                                    <D5
                                      value={datas[indexes[__]][___]}
                                      label={labels[___]}
                                      open={
                                        opens[indexes[__] + "-" + ___] === true
                                      }
                                      close={() => {
                                        let temporary = Lodash.clone(opens);
                                        temporary[
                                          indexes[__] + "-" + ___
                                        ] = !temporary[indexes[__] + "-" + ___];
                                        setOpens(temporary);
                                      }}
                                      change={(event) => {
                                        if (locked.includes(___)) {
                                          return false;
                                        }
                                        let clone = Lodash.clone(datas);
                                        clone[indexes[__]][___] = event;
                                        set(clone);

                                        if (change) {
                                          change(clone);
                                        }
                                      }}
                                      {...object[___]}
                                    />
                                    <Rule
                                      display="block"
                                      color={Colors.primary}
                                      press={() => {
                                        let temporary = Lodash.clone(opens);
                                        temporary[
                                          indexes[__] + "-" + ___
                                        ] = !temporary[indexes[__] + "-" + ___];
                                        setOpens(temporary);
                                      }}
                                    >
                                      Expand
                                    </Rule>
                                  </>
                                ) : OPTIONS(___, _) ? (
                                  <D6
                                    options={OPTIONS(___, _)}
                                    value={____}
                                    title={labels[___]}
                                    change={(value) => {
                                      if (locked.includes(___)) {
                                        return false;
                                      }
                                      let temporary = value;

                                      if (numeric.includes(___)) {
                                        if (typeof temporary === "string") {
                                          if (temporary.length === 0) {
                                            temporary = 0;
                                          } else {
                                            temporary = parseFloat(
                                              temporary,
                                              10
                                            );
                                          }
                                        }
                                      }

                                      let clone = Lodash.clone(datas);
                                      clone[indexes[__]][___] = temporary;
                                      set(clone);

                                      if (change) {
                                        change(clone);
                                      }
                                    }}
                                  />
                                ) : (
                                  <input
                                    style={{
                                      border: "none",
                                      borderRadius: 2,
                                      boxSizing: "border-box",
                                      width: "100%",
                                      display: "block",
                                      fontSize: professional ? 14 : 16,
                                      fontFamily: "Programme Regular",
                                      color: Colors.black,
                                      outline: "none",
                                      background: "none",
                                      backgroundColor: "none",
                                    }}
                                    value={____}
                                    onChange={(event) => {
                                      if (locked.includes(___)) {
                                        return false;
                                      }
                                      let temporary = event.target.value;

                                      if (numeric.includes(___)) {
                                        if (typeof temporary === "string") {
                                          if (temporary.length === 0) {
                                            temporary = 0;
                                          } else {
                                            temporary = parseFloat(
                                              temporary,
                                              10
                                            );
                                          }
                                        }
                                      }

                                      let clone = Lodash.clone(datas);
                                      clone[indexes[__]][___] = temporary;
                                      set(clone);

                                      if (change) {
                                        change(clone);
                                      }
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    <Box
                      display="flex"
                      align="center"
                      direction="column"
                      left={professional ? 4 : 12}
                    >
                      {trash ? (
                        <Icon
                          size={professional ? 12 : 16}
                          opacity={0.5}
                          icon="minus"
                          press={() => {
                            let clone = Lodash.clone(datas);
                            clone.splice(indexes[__], 1);
                            set(clone);

                            if (change) {
                              change(clone);
                            }
                          }}
                        />
                      ) : undefined}
                      {move ? (
                        <>
                          <Icon
                            size={professional ? 12 : 16}
                            opacity={0.5}
                            icon="chevron-up"
                            press={() => {
                              let clone = Lodash.clone(datas);

                              if (indexes[__] > 0) {
                                let temporary = Lodash.clone(
                                  clone[indexes[__] - 1]
                                );
                                clone[indexes[__] - 1] = Lodash.clone(
                                  clone[indexes[__]]
                                );
                                clone[indexes[__]] = temporary;
                                set(clone);
                                if (change) {
                                  change(clone);
                                }
                              }
                            }}
                          />
                          <Icon
                            size={professional ? 12 : 16}
                            opacity={0.5}
                            icon="chevron-down"
                            press={() => {
                              let clone = Lodash.clone(datas);

                              if (indexes[__] < clone.length - 1) {
                                let temporary = Lodash.clone(
                                  clone[indexes[__] + 1]
                                );
                                clone[indexes[__] + 1] = clone[indexes[__]];
                                clone[indexes[__]] = temporary;
                                set(clone);
                                if (change) {
                                  change(clone);
                                }
                              }
                            }}
                          />
                        </>
                      ) : undefined}
                      {duplicate ? (
                        <Icon
                          size={professional ? 12 : 16}
                          opacity={0.5}
                          icon="copy"
                          press={() => {
                            let clone = Lodash.clone(datas);
                            clone.push(Lodash.clone(_));
                            set(clone);

                            if (change) {
                              change(clone);
                            }
                          }}
                        />
                      ) : undefined}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        />
      )}
    />
  );
};

export default Component;
