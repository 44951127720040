import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  Search,
  Badge,
  Mobile,
  Input,
} from "../Bitter";
import { Permissions } from "../System";
import { Colors } from "../Helpers";
import { Pages, API } from "../Modules";
import { Empty } from "../Project";
import PuffLoader from "react-spinners/PuffLoader";

const Entry = ({
  settings,
  entity,
  press,
  double,
  active,
  border = false,
  layer = 0,
  applicable = false,
  professional = true,
}) => {
  return (
    <Mobile
      render={(mobile) => (
        <Box
          key={entity.uuid}
          mode="padding"
          top={8}
          bottom={8}
          left={8}
          left={8}
          display="flex"
          align="flex-start"
          direction={mobile ? "column" : "row"}
          press={() => {
            if (press) {
              press(entity.uuid);
            }
          }}
          color={active ? "#FCFCFC" : Colors.white}
          style={{
            borderBottom:
              border || (applicable && mobile)
                ? "2px solid #F7F7F7"
                : undefined,
          }}
        >
          {settings.columns.map((column) =>
            column.type === "custom" && !mobile ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
              >
                {column.label ? (
                  <Rule rule="Tiny" bottom={4} opacity={0.25}>
                    {column.label}
                  </Rule>
                ) : undefined}
                {column.value ? column.value(entity) : undefined}
              </Box>
            ) : column.type === "identifier" ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
                {...(() => {
                  if (column.rules) {
                    if (column.rules().jsx) {
                      return column.rules().jsx;
                    }
                  }
                })()}
              >
                <Rule rule="Tiny" bottom={4} opacity={0.25}>
                  {column.label}
                </Rule>
                <Rule rule={mobile ? "Small" : "Body"}>
                  {column.value(entity)}
                </Rule>
                {professional ? (
                  <Rule rule="Small" opacity={0.5} top={4}>
                    {entity.uuid.substring(0, 8) +
                      entity.uuid.substring(entity.uuid.length - 8)}
                  </Rule>
                ) : undefined}
              </Box>
            ) : column.type === "string" ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
                press={column.press ? () => column.press(entity) : undefined}
              >
                <Rule rule="Tiny" bottom={4} opacity={0.25}>
                  {column.label}
                </Rule>
                <Box display="flex" align="center" direction="row">
                  {typeof column.icon === "function" ? (
                    column.icon(entity) ? (
                      <Icon
                        right={4}
                        size={16}
                        style={{ strokeWidth: 3 }}
                        icon={
                          column.icon(entity).split("@").length > 1
                            ? column.icon(entity).split("@")[0]
                            : column.icon(entity)
                        }
                        color={
                          column.icon(entity).split("@").length > 1
                            ? column.icon(entity).split("@")[1]
                            : Colors.black
                        }
                      />
                    ) : undefined
                  ) : undefined}
                  <Rule
                    display="block"
                    rule={mobile ? "Small" : "Thin"}
                    color={
                      typeof column.rules === "function"
                        ? column.rules(entity)
                          ? column.rules(entity).color
                          : undefined
                        : undefined
                    }
                    style={{ width: "100%", whiteSpace: "nowrap" }}
                  >
                    {column.value(entity) ? (
                      column.value(entity).length > 0 ? (
                        column.value(entity).length > 12 ? (
                          column.value(entity).substring(0, 12) + "..."
                        ) : (
                          column.value(entity)
                        )
                      ) : (
                        <Box
                          height={14}
                          top={2}
                          bottom={2}
                          width="100%"
                          color="#F7F7F7"
                          radius={4}
                        />
                      )
                    ) : (
                      <Box
                        height={14}
                        top={2}
                        bottom={2}
                        width="100%"
                        color="#F7F7F7"
                        radius={4}
                      />
                    )}
                  </Rule>
                </Box>
              </Box>
            ) : column.type === "badge" && !mobile ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
              >
                <Rule rule="Tiny" bottom={4} opacity={0.25}>
                  {column.label}
                </Rule>
                <Badge>{column.value(entity)}</Badge>
              </Box>
            ) : column.type === "entity" && !mobile ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
                press={() =>
                  Pages.screen(
                    "@entity/" + column.rules(entity).api,
                    column.value(entity),
                    false
                  )
                }
              >
                <Rule rule="Tiny" bottom={4} opacity={0.25}>
                  {column.label}
                </Rule>
                <Rule rule="Thin" color={Colors.primary}>
                  {column.value(entity)}
                </Rule>
              </Box>
            ) : column.type === "long" && !mobile ? (
              <Box
                flex={1}
                display="flex"
                direction="column"
                mode="padding"
                right={mobile ? 0 : 16}
                bottom={mobile ? 4 : 0}
              >
                <Rule rule="Tiny" bottom={4} opacity={0.25}>
                  {column.label}
                </Rule>
                <Rule
                  rule="Thin"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.value(entity)}
                </Rule>
              </Box>
            ) : undefined
          )}
          <Box
            height={mobile ? undefined : 55}
            display="inline-flex"
            align="center"
            justify="center"
            right={mobile ? 0 : 8}
            bottom={mobile ? 8 : 0}
          >
            <Button
              type="disabled"
              size="small"
              press={() =>
                Pages.screen(
                  "@entity/" + settings.api,
                  entity.uuid,
                  settings.temporary ? settings.temporary(entity) : false
                )
              }
            >
              Details
            </Button>
          </Box>
          {settings.columns.map((column) =>
            column.type === "button" && !mobile ? (
              <Box
                height={mobile ? undefined : 55}
                display="inline-flex"
                align="center"
                justify="center"
                right={mobile ? 0 : 8}
              >
                <Button
                  type="disabled"
                  size="small"
                  {...column.value(entity, this)}
                />
              </Box>
            ) : undefined
          )}
        </Box>
      )}
    />
  );
};

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entities: [],
      loading: true,
      search: "",
      active: "",
      page: 1,
      limit: 20,
      timer: false,
    };
  }

  async componentDidMount() {
    const { settings } = this.props;
    console.log({ settings });
    const { page, limit } = this.state;
    const entities = await API.request("/@" + settings.api + "/read", {
      ...settings.options,
      page,
      limit,
    });
    if (entities.success) {
      this.setState({ entities: entities.data, loading: false });
    }

    this.timeout = false;
  }

  reload = async () => {
    const { settings } = this.props;
    const { page, limit } = this.state;
    const entities = await API.request("/@" + settings.api + "/read", {
      ...settings.options,
      page,
      limit,
    });
    if (entities.success) {
      this.setState({ entities: entities.data, loading: false });
    }
  };

  search = async () => {
    const { settings } = this.props;
    const { page, limit, search } = this.state;
    if (search.length === 0) {
      await this.reload();
    } else {
      const entities = await API.request("/@" + settings.api + "/read", {
        ...settings.options,
        page,
        limit,
        mode: "search",
        match: search,
      });
      if (entities.success) {
        this.setState({ entities: entities.data, loading: false });
      }
    }
    this.setState({ timer: false });
    clearTimeout(this.timeout);
  };

  render() {
    const { settings } = this.props;
    const { professional } = this.props.redux;
    const {
      entities: temporary,
      loading,
      search,
      active,
      page,
      limit,
      timer,
    } = this.state;
    const first = Pages.first();

    let entities = temporary.filter((entity) => {
      if (search.length > 0) {
        if (
          !JSON.stringify(entity).toLowerCase().includes(search.toLowerCase())
        ) {
          return false;
        }
      }

      if (settings.filter) {
        if (!settings.filter(entity)) {
          return false;
        }
      }

      return true;
    });

    return (
      <Mobile
        render={(mobile) => (
          <Box
            width="100%"
            height="100%"
            direction="column"
            flex={1}
            display="flex"
            color="#FCFCFC"
            style={{ borderLeft: mobile ? undefined : "2px solid #F7F7F7" }}
          >
            <Box
              color={Colors.white}
              mode="padding"
              all={24}
              direction="row"
              align="center"
              justify="space-between"
              display="flex"
            >
              <Box direction="row" align="center" display="flex">
                {first ? undefined : (
                  <Icon
                    press={() => Pages.previous()}
                    icon="chevron-left"
                    right={12}
                  />
                )}
                <Box direction="row" align="flex-end" display="flex">
                  <Rule rule="Header" right={12}>
                    {settings.label}
                  </Rule>
                </Box>
              </Box>
              <Box direction="row" align="center" display="flex">
                {settings.rules.create &&
                Permissions.permission("create:" + settings.api) ? (
                  <Box left={12}>
                    <Button
                      type="dark"
                      press={() =>
                        Pages.screen(
                          "@create/" + settings.api,
                          false,
                          settings.temporary,
                          settings.override
                        )
                      }
                    >
                      Create
                    </Button>
                  </Box>
                ) : undefined}
                {settings.rules.custom.length > 0 && Permissions.permission("*")
                  ? settings.rules.custom.map((c) => <Box left={12}>{c}</Box>)
                  : undefined}
              </Box>
            </Box>
            <Line />

            <Mobile
              render={(mobile) => (
                <Box
                  width="100%"
                  mode="padding"
                  all={24}
                  height="100%"
                  overflow="auto"
                  style={mobile ? { paddingBottom: 92 } : undefined}
                >
                  {loading ? (
                    <Box
                      mode="padding"
                      all={16}
                      align="center"
                      justify="center"
                      display="flex"
                    >
                      <PuffLoader
                        color={Colors.primary}
                        loading={true}
                        size={32}
                      />
                    </Box>
                  ) : (
                    <>
                      <Box bottom={12}>
                        <Search
                          value={search}
                          change={(search) => {
                            this.setState({ search, timer: true });
                            this.setState({ timer: false });
                            clearTimeout(this.timeout);
                            this.timeout = setTimeout(this.search, 300);
                            this.setState({ timer: true });
                          }}
                          loading={timer}
                        />
                      </Box>
                      {entities.length > 0 ? (
                        <Box
                          radius={8}
                          shadow="medium"
                          mode="padding"
                          left={8}
                          top={8}
                          bottom={8}
                          right={8}
                          color={Colors.white}
                        >
                          {entities.map((entity, e) => (
                            <Entry
                              professional={professional}
                              layer={e}
                              active={active === entity.uuid}
                              press={(uuid) => {
                                clearTimeout(this.timer);
                                if (active === entity.uuid) {
                                  Pages.screen(
                                    "@entity/" + settings.api,
                                    entity.uuid,
                                    settings.temporary
                                      ? settings.temporary(entity)
                                      : false
                                  );
                                } else {
                                  this.setState({ active: uuid });
                                  this.timer = setTimeout(
                                    () => this.setState({ active: "" }),
                                    300
                                  );
                                }
                              }}
                              settings={settings}
                              entity={entity}
                              border={
                                e < entities.length - 1
                                  ? settings.rules.border
                                  : undefined
                              }
                              applicable={e < entities.length - 1}
                            />
                          ))}
                        </Box>
                      ) : (
                        <Empty
                          title="Oops"
                          description="We couldn't find any results. You can try changing the filtering options. If you think this is an error, please refresh the page."
                        />
                      )}
                    </>
                  )}
                </Box>
              )}
            />

            <Mobile
              render={(mobile) => (
                <Box color={Colors.white}>
                  <Line />
                  <Box
                    mode="padding"
                    all={mobile ? 12 : 24}
                    display="flex"
                    align="center"
                    justify="flex-start"
                  >
                    <Box right={mobile ? 6 : 12} flex={mobile ? 1 : undefined}>
                      <Input
                        change={(limit) => {
                          this.setState({ limit: parseInt(limit, 10) });
                          this.setState({ timer: false });
                          clearTimeout(this.timeout);
                          this.timeout = setTimeout(this.search, 300);
                          this.setState({ timer: true });
                        }}
                        type="number"
                        label="Limit"
                        value={limit}
                        size={mobile ? "small" : "medium"}
                      />
                    </Box>
                    <Box left={mobile ? 6 : 12} flex={mobile ? 1 : undefined}>
                      <Input
                        change={(page) => {
                          this.setState({ page: parseInt(page, 10) });
                          this.setState({ timer: false });
                          clearTimeout(this.timeout);
                          this.timeout = setTimeout(this.search, 300);
                          this.setState({ timer: true });
                        }}
                        type="number"
                        label="Page"
                        value={page}
                        size={mobile ? "small" : "medium"}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            />
          </Box>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
