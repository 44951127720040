import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      choose: false,
      search: "",
    };
  }

  async componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ value });
    }
  }

  render() {
    const { options, title, change } = this.props;
    const { value, choose, search } = this.state;

    let force = true;

    options.map((element) => {
      if (element.value === value) {
        force = false;
      }
    });

    return (
      <>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.30)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{title}</Rule>
                <Box direction="row" display="inline-flex">
                  <Box left={12}>
                    <Button
                      type="white"
                      press={() => this.setState({ choose: false })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
              {options.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {options.map((element, e) => {
                    if (search.length > 0) {
                      if (
                        !JSON.stringify(element)
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return undefined;
                      }
                    }

                    return (
                      <Box
                        key={element.uuid || e}
                        mode="padding"
                        top={8}
                        bottom={8}
                        display="flex"
                        align="center"
                        direction="row"
                      >
                        <Box
                          flex={1}
                          display="flex"
                          direction="column"
                          mode="padding"
                          right={16}
                        >
                          <Rule rule="Body" bottom={element.name ? 4 : 0}>
                            {element.label || element.value}
                          </Rule>
                          {element.name ? (
                            <Rule rule="Small" opacity={0.5}>
                              {element.name}
                            </Rule>
                          ) : undefined}
                        </Box>
                        <Button
                          type="disabled"
                          size="small"
                          press={() => {
                            this.setState({
                              value: element.value,
                              choose: false,
                            });
                            if (change) {
                              change(element.value);
                            }
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Rule
          display="block"
          color={Colors.primary}
          press={() => this.setState({ choose: true })}
          style={{
            height: 18,
            overflow: "hidden",
          }}
        >
          {force
            ? "Choose"
            : options.map((element) => {
                if (element.value === value) {
                  return element.label;
                }
              })}
        </Rule>
      </>
    );
  }
}

export default Component;
