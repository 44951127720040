import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      choose: false,
      search: ""
    };
  }

  async componentDidMount() {
    const { entity } = this.props;
    if (entity) {
      this.setState({ entity });
    }
  }

  render() {
    const { entities, title, change } = this.props;
    const { entity, choose, search } = this.state;

    return (
      <>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.10)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{title}</Rule>
                <Box direction="row" display="inline-flex">
                  <Box left={12}>
                    <Button
                      type="white"
                      press={() => this.setState({ choose: false })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
              {entities.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {entities.map((element, e) => (
                    <Box
                      key={element.uuid || e}
                      mode="padding"
                      top={8}
                      bottom={8}
                      display="flex"
                      align="center"
                      direction="row"
                    >
                      <Box
                        flex={1}
                        display="flex"
                        direction="column"
                        mode="padding"
                        right={16}
                      >
                        {element.name ? (
                          <Rule rule="Body" bottom={element.label ? 4 : 0}>
                            {element.name}
                          </Rule>
                        ) : undefined}
                        {element.label ? (
                          <Rule rule="Small" opacity={0.5}>
                            {element.label}
                          </Rule>
                        ) : undefined}
                      </Box>
                      <Button
                        type="disabled"
                        size="small"
                        press={() => {
                          this.setState({ entity: element, choose: false });
                          if (change) {
                            change(element);
                          }
                        }}
                      >
                        Choose
                      </Button>
                    </Box>
                  ))}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction="row"
          align="center"
          justify="space-between"
        >
          <Box>
            {entity ? (
              <>
                <Rule rule="Bold" display="block" bottom={4}>
                  {entity.name}
                </Rule>
                <Rule rule="Small" display="block" opacity={0.5}>
                  {entity.label}
                </Rule>
              </>
            ) : (
              <>
                <Rule rule="Thin" display="block" bottom={4}>
                  Not Selected
                </Rule>
              </>
            )}
          </Box>
          <Box display="flex" align="center" direction="row">
            <Box left={12}>
              <Button press={() => this.setState({ choose: true })} type="dark">
                Choose
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default Component;
