import React from "react";
import FeatherIcon from "feather-icons-react";
import { Box } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import Lodash from "lodash";

const Component = (props) => {
  const { icon, size = 24, press, color, weight, style } = props;

  let properties = props;
  if (properties.color) {
    properties = Lodash.omit(properties, "color");
  }

  return (
    <Box
      height={size}
      width={size}
      press={press}
      {...properties}
      styling={press ? "--button" : undefined}
    >
      {Functions.url(icon) ? (
        <img width={size} height={size} alt={icon} src={icon} />
      ) : (
        <FeatherIcon
          size={size}
          icon={icon}
          stroke={color ? color : Colors.black}
          weight={weight}
          style={{ ...style, strokeWidth: weight }}
        />
      )}
    </Box>
  );
};

export default Component;
