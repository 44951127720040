import Assistant from "../Assistant";
import M from "../Minifier";
import Permissions from "../Permissions";
import { Functions, Resources } from "../../Helpers";
import { Clipboard, Popups, Sequence, Actions, Pages } from "../../Modules";
import { Documentation, Box, Button } from "../../Bitter";
import { Custom, ProformaDocument, Proforma } from "../../Project";
import { ProformaSequence } from "../../Sequence";
import Lodash from "lodash";
import Toast from "react-hot-toast";
import Moment from "moment";

const Bite = {
  bite: () => {
    return Assistant.entity(
      "users",
      (entity) => entity.parameters.username,
      ["update", "delete"],
      [
        Assistant.block.string("name", "Name"),
        Assistant.block.string("username", "Username"),
        Assistant.block.string("email", "Email"),
        Assistant.block.password("password", "Password"),
        Assistant.block.entities("Accounts", (entity) => {
          return {
            display: true,
            create: true,
            api: "accounts",
            filter: (temporary) => {
              return {
                parameters: {
                  user: entity.uuid,
                },
              };
            },
            override: {
              user: entity.uuid,
            },
            label: (temporary) => temporary.permissions.parameters.label,
          };
        }),
      ]
    );
  },
};

export default Bite;
